import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { Collapse } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const { Panel } = Collapse;

const FaqContentDisplayPanel = ({ faq }) => {
  const { t } = useTranslation();

  const translateContent = (content) => {
    if (typeof content === "string") {
      return t(content);
    } else {
      // Render JSX content directly
      return content;
    }
  };

  return (
    <Collapse
      bordered={false}
      expandIconPosition="end"
      className="custom-antd-collapse"
      expandIcon={({ isActive }) =>
        isActive ? (
          <MinusCircleFilled className="faqCollapseBtn" />
        ) : (
          <PlusCircleFilled className="faqCollapseBtn" />
        )
      }
    >
      {faq.map((item, index) => (
        <Panel header={t(item.q)} key={index}>
          {translateContent(item.a)}
        </Panel>
      ))}
    </Collapse>
  );
};

export default FaqContentDisplayPanel;
