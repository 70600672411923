import { HashRouter as Router } from "react-router-dom";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import AppRoutes from "./components/routes/routes";
import ScrollToTop from "./components/routes/ScrollTop";
import "./stylings/App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Header />
          <AppRoutes />
          <Footer />
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
