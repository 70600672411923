import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import BenefitItem from "../components/agentRecruit/agentBenefits";
import UseScreenSize from "../components/useScreenSize";
import {
  agentElement2,
  commission,
  currency,
  protection,
  stopwatch,
  support,
  tracking,
  transparent,
} from "../img";
import "../stylings/recruitment.css";

const Recruitment = () => {
  const screenSize = UseScreenSize();
  const isMobile = screenSize?.width < 768;
  const { t } = useTranslation();
  return (
    <div>
      <div className="recruitFirstBlock">
        <div className="blockContainer">
          <div className="blockContainer2">
            <div className="firstBlockContent">
              <div className="firstBlockTitle">
                {t("joinCg1")}{" "}
                <span className="orangeText">{t("joinCg2")}</span>
              </div>
              <div style={{ fontWeight: 300, fontSize: "14px" }}>
                {t("joinCgDesc")}
              </div>
              <Button className="learnMoreBtn">{t("agentLogin")}</Button>
              <Button className="learnMoreBtn">{t("contactUs")}</Button>
            </div>
            <img
              className="highCommsImgBg"
              src={isMobile ? agentElement2 : agentElement2}
              alt="High Commissions"
            />
          </div>
        </div>
      </div>
      <div className="benefitContainer">
        <div className="benefitTitle">
          <span>{t("benefitJoin")}</span>{" "}
          <span style={{ color: "#067449" }}>{t("cash")}</span>
          <span style={{ color: "#F7C212" }}>{t("go")}</span>{" "}
          <span>{t("agent")}</span>
        </div>
        <div className="benefitDesc">{t("benefitJoinDesc")}</div>
        <div className="benefitGrid">
          <BenefitItem
            imgSrc={commission}
            title="benefitJoin1"
            description="benefitJoin1Desc"
          />
          <BenefitItem
            imgSrc={currency}
            title="benefitJoin2"
            description="benefitJoin2Desc"
          />
          <BenefitItem
            imgSrc={protection}
            title="benefitJoin3"
            description="benefitJoin3Desc"
          />
          <BenefitItem
            imgSrc={stopwatch}
            title="benefitJoin4"
            description="benefitJoin4Desc"
          />
        </div>
      </div>
      <div className="agentLevelContainer">
        <div className="agentLevelContent">
          <div
            style={{
              fontWeight: "600",
              fontSize: isMobile ? "24px" : "40px",
            }}
          >
            {t("3level")} <span style={{ color: "#F4AC0A" }}>{t("cg")}</span>{" "}
            {t("agent")}
          </div>
          <div
            style={{ fontWeight: 300, fontSize: isMobile ? "14px" : "18px" }}
          >
            {t("3levelDesc")}
          </div>
        </div>
      </div>
      <div className="currencyExContainer">
        <img src={agentElement2} alt="High Commission" />
        <div className="currencyExContent">
          <div>
            <div
              style={{
                fontWeight: 700,
                fontSize: isMobile ? "24px" : "32px",
                borderRadius: isMobile ? "16px" : "",
                backgroundColor: isMobile ? "#05754F" : "",
                textAlign: isMobile ? "center" : "",
                color: isMobile ? "white" : "",
              }}
            >
              <span style={{ color: isMobile ? "#F4AC0A" : "#067449" }}>
                {t("cash")}
              </span>
              <span style={{ color: "#F4AC0A" }}>{t("go")}</span>{" "}
              {t("currencyEx")}
            </div>
            <div style={{ fontWeight: 300, fontSize: "14px" }}>
              {t("currencyExDesc")}
            </div>
          </div>
          <div className="innerDiv">
            <img src={transparent} alt="Transparency" />
            <div>
              <div
                style={{ color: "#067449", fontWeight: 700, fontSize: "18px" }}
              >
                {t("transparency")}
              </div>
              <div style={{ fontWeight: 300, fontSize: "14px" }}>
                {t("transparencyDesc")}
              </div>
            </div>
          </div>
          <div className="innerDiv">
            <img src={tracking} alt="Tracking" />
            <div>
              <div
                style={{ color: "#F4AC0A", fontWeight: 700, fontSize: "18px" }}
              >
                {t("tracing")}
              </div>
              <div style={{ fontWeight: 300, fontSize: "14px" }}>
                {t("tracingDesc")}
              </div>
            </div>
          </div>
          <div className="innerDiv">
            <img src={support} alt="Support" />
            <div>
              <div
                style={{ color: "#067449", fontWeight: 700, fontSize: "18px" }}
              >
                {t("custSupport")}
              </div>
              <div style={{ fontWeight: 300, fontSize: "14px" }}>
                {t("custSupportDesc")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Recruitment;
