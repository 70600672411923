import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import UseScreenSize from "../components/useScreenSize";
import {
  androidQr,
  appStore,
  appleQr,
  appstoreLogo,
  bkash,
  cgPhoneApp,
  downloadIcon1,
  downloadIcon2,
  downloadIcon3,
  ezpay,
  nagad,
  pcLogo,
  playStore,
  playstoreLogo,
  rocket,
  star,
} from "../img";
import "../stylings/download.css";
import "../stylings/home.css";

const Download = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screenSize = UseScreenSize();
  const isMobile = screenSize?.width < 768;

  return (
    <>
      <div>
        <div className="fourthBlock">
          <img
            src={cgPhoneApp}
            alt="CashGo App"
            style={{ height: "300px", width: "300px" }}
          />
          <div className="fourthBlockMainContainer">
            <div
              className="greenText"
              style={{
                fontWeight: isMobile ? 600 : 700,
                fontSize: isMobile ? "21px" : "36px",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              {t("smoothExp1")}{" "}
              <span style={{ color: "white" }}>{t("smoothExp2")}</span>
            </div>
            <div
              style={{
                fontWeight: 500,
                fontSize: "14px",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              {t("whetherPrefer1")}{" "}
              <span style={{ color: "black" }}>{t("whetherPrefer2")}</span> -
              {t("whetherPrefer2")}
            </div>
            {!isMobile && (
              <div
                style={{
                  fontSize: "0.8em",
                  fontWeight: 450,
                  paddingTop: "12px",
                  padding: isMobile ? "8px" : "0px",
                  textAlign: isMobile ? "center" : "justify",
                  width: isMobile ? "" : "500px",
                }}
              >
                {t("smoothExpDesc")}
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                padding: "4px",
              }}
            >
              <Button className="learnMoreBtn">
                <a
                  href="https://download.cashgo88.com/cashgo.apk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("downloadNow")}
                </a>
              </Button>
              <Button className="learnMoreBtn">
                <Link to={"/register"}>{t("joinNow")}</Link>
              </Button>
            </div>
            <div className="fourthBlockQrMain">
              <div className="qRContainer">
                <img src={appleQr} alt="Apple QR" className="qrImg" />
                <img src={appStore} alt="App Store" className="qrStoreImg" />
                <div className="comingSoonText">{t("cmingSoon")}</div>
              </div>
              <div className="qRContainer">
                <img src={androidQr} alt="Android QR" className="qrImg" />
                <img src={playStore} alt="Play Store" className="qrStoreImg" />
              </div>
              <div className="qrTextContainer">
                <div>
                  <div className="qrTextContainerWord">{t("appDownload")}</div>
                  <div className="qrTextContainerBold">{t("11m")}</div>
                </div>
                <div>
                  <div className="qrTextContainerWord">{t("userReview")}</div>
                  <div className="qrTextContainerBold">{t("5star")}</div>
                </div>
                <div>
                  <div className="qrTextContainerWord">{t("availableOn")}</div>
                  <div className="fourthBlockAvailIconPack">
                    <img src={appstoreLogo} alt="App Store" />
                    <img src={playstoreLogo} alt="Play Store" />
                    <img src={pcLogo} alt="PC" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="downloadContainer">
          <img src={downloadIcon1} alt="Convenient Payment" />
          <div className="innerDiv">
            <div style={{ color: "black", fontWeight: 700 }}>
              {t("poweringPayment")}
            </div>
            <div
              className="greenText"
              style={{ fontWeight: 600, fontSize: "16px" }}
            >
              {t("smoothMgmt")}
            </div>
            <div className="innerDiv2">{t("smoothMgmtDesc")}</div>
            <Button
              className="downloadBtn experienceBtn"
              onClick={() => navigate("/register")}
            >
              {t("expNow")}
            </Button>
          </div>
        </div>
        <div className="downloadContainer2 greyBg">
          <div className="innerDiv">
            <div style={{ color: "black", fontWeight: 700 }}>
              {t("goBeyond")}
            </div>
            <div
              className="greenText"
              style={{ fontWeight: 600, fontSize: "16px" }}
            >
              {t("goBeyondDesc")}
            </div>
            <div className="innerDiv2">
              <div className="goBeyondContainer">
                <img src={star} alt="Star Icon" />
                <div>{t("goBeyondBenefit1")}</div>
              </div>
              <div className="goBeyondContainer">
                <img src={star} alt="Star Icon" />
                <div>{t("goBeyondBenefit2")}</div>
              </div>
            </div>
            <Button
              className="downloadBtn tryNowBtn"
              onClick={() => navigate("/register")}
            >
              {t("tryNow")}
            </Button>
          </div>
          <img src={downloadIcon2} alt="Send Money" />
        </div>
        <div className="downloadContainer">
          <img src={downloadIcon3} alt="Payment" />
          <div className="innerDiv">
            <div style={{ color: "black", fontWeight: 700 }}>
              {t("safeGateway")}
            </div>
            <div
              className="greenText"
              style={{ fontWeight: 600, fontSize: "16px" }}
            >
              {t("safeGatewayDesc")}
            </div>
            <div className="supportPayment">
              <img src={rocket} alt="rocket" />
              <img src={nagad} alt="nagad" />
              <img src={bkash} alt="bkash" />
              <img src={ezpay} alt="ezpay" />
            </div>
            <Button
              className="downloadBtn experienceBtn"
              onClick={() => navigate("/register")}
            >
              {t("learnMore")}
            </Button>
          </div>
        </div>
        <div className="downloadContainer" style={{ minHeight: 0 }}>
          <div className="dataContainer">
            <div style={{ color: "black", fontWeight: 700 }}>
              <span>{t("empowering")}</span>{" "}
              <span className="greenText">{t("empowering2")}</span>
            </div>
            <div className="innerContent">
              <div className="dataDisplay">
                <div className="dataTitle">{t("empoweringTitle1")}</div>
                <div className="dataDesc">{t("empoweringTitle1Desc")}</div>
              </div>
              <div className="dataDisplay">
                <div className="dataTitle">{t("empoweringTitle2")}</div>
                <div className="dataDesc">{t("empoweringTitle2Desc")}</div>
              </div>
              <div className="dataDisplay">
                <div className="dataTitle">{t("empoweringTitle3")}</div>
                <div className="dataDesc">{t("empoweringTitle2Desc")}</div>
              </div>
              <div className="dataDisplay">
                <div className="dataTitle">{t("empoweringTitle4")}</div>
                <div className="dataDesc">{t("empoweringTitle4Desc")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Download;
