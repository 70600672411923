import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { sportsMaxbet } from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";
import { Link } from "react-router-dom";

const MaxbetPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={sportsMaxbet} width={200} alt="MAXBET" />
          <div className="info-container">
            <div className="info-title">{t("MAXBET")}</div>
            <div className="info-desc">{t("maxbetMainDesc1")}</div>
            <div className="info-desc" style={{ paddingTop: "10px" }}>
              {t("maxbetMainDesc2")}
            </div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("maxbetPageTitle1")}</div>
          <div className="info-text-desc">{t("maxbetPageTitle1Desc1")}</div>
          <div className="info-text-desc">{t("maxbetPageTitle1Desc2")}</div>
          <div className="info-text-desc">{t("maxbetPageTitle1Desc3")}</div>
          <div className="info-text-title2">{t("maxbetPageTitle2")}</div>
          <div className="info-text-desc">{t("maxbetPageTitle2Desc1")}</div>
          <div className="info-text-desc">{t("maxbetPageTitle2Desc2")}</div>
          <div className="info-text-title2">{t("maxbetPageTitle3")}</div>
          <div className="info-text-desc">{t("maxbetPageTitle3Desc")}</div>
          <div className="info-text-title2">{t("maxbetPageTitle4")}</div>
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <div>
              <ul className="info-text-desc">
                <li>{t("E-Sports")}</li>
                <li>{t("Horse Racing")}</li>
                <li>{t("Swimming")}</li>
                <li>{t("Darts")}</li>
                <li>{t("Boxing")}</li>
                <li>{t("Water Polo")}</li>
                <li>{t("Bike Grand Prix")}</li>
                <li>{t("Golf")}</li>
              </ul>
            </div>
            <div>
              <ul className="info-text-desc">
                <li>{t("Snooker")}</li>
                <li>{t("Volleyball")}</li>
                <li>{t("Table Tennis")}</li>
                <li>{t("Badminton")}</li>
                <li>{t("Tennis")}</li>
                <li>{t("Baseball Hockey")}</li>
                <li>{t("Basketball")}</li>
                <li>{t("Football")}</li>
              </ul>
            </div>
          </div>
          <div className="info-text-desc" style={{ padding: "0px 30px" }}>
            {t("maxbetPageTitle4Desc1")}
          </div>
          <div className="info-text-desc">{t("maxbetPageTitle4Desc2")}</div>
          <div className="info-text-title2">{t("maxbetPageTitle5")}</div>
          <div className="info-text-desc">{t("maxbetPageTitle5Desc")}</div>
        </div>
      </div>
    </div>
  );
};

export default MaxbetPage;
