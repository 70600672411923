import { Trans } from "react-i18next";
import { bkash, nagad, rocket } from "../../img";
import "../../stylings/faqStyling.css";

export const faqContent = {
  general: [
    {
      q: "faqGeneral1Title",
      a: "faqGeneral1Desc",
    },
    {
      q: "faqGeneral2Title",
      a: (
        <>
          <Trans i18nKey="faqGeneral2Desc" />
          <ul style={{ listStyleType: "inherit" }}>
            <li>
              <Trans i18nKey="faqGeneral2Desc2" />
            </li>
            <li>
              <Trans i18nKey="faqGeneral2Desc3" />
            </li>
            <li>
              <Trans i18nKey="faqGeneral2Desc4" />
            </li>
          </ul>
        </>
      ),
    },
    {
      q: "faqGeneral3Title",
      a: (
        <>
          <Trans i18nKey="faqGeneral3Desc" />{" "}
          <a
            className="url-link"
            href="https://www.cash-go.com/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans i18nKey="registerLink" />
          </a>
        </>
      ),
    },
    {
      q: "faqGeneral4Title",
      a: "faqGeneral4Desc",
    },
    {
      q: "faqGeneral5Title",
      a: (
        <>
          <Trans i18nKey="faqGeneral5Desc" />{" "}
          <span style={{ fontWeight: 600, color: "black" }}>
            <Trans i18nKey="chatBtn" />
          </span>{" "}
          <Trans i18nKey="faqGeneral5Desc2" />
        </>
      ),
    },
    {
      q: "faqGeneral6Title",
      a: (
        <>
          <Trans i18nKey="faqGeneral6Desc" />{" "}
          <a
            className="url-link"
            href="https://www.cash-go.com/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans i18nKey="faqSection" />
          </a>
          .
        </>
      ),
    },
  ],
  account: [
    {
      q: "faqAccount1Title",
      a: (
        <>
          <Trans i18nKey="faqAccount1Desc" />{" "}
          <a
            className="url-link"
            href="https://www.cash-go.com/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans i18nKey="registerPage" />
          </a>{" "}
          <Trans i18nKey="faqAccountDesc2" />
        </>
      ),
    },
    {
      q: "faqAccount2Title",
      a: (
        <>
          <Trans i18nKey="faqAccount2Desc" />
          <br />
          <Trans i18nKey="faqAccount2Desc2" />{" "}
          <a
            className="url-link"
            href="https://www.cash-go.com/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans i18nKey="register2" />
          </a>{" "}
          <Trans i18nKey="faqAccount2Desc3" />
        </>
      ),
    },
    {
      q: "faqAccount3Title",
      a: "faqAccount3Desc",
    },
    {
      q: "faqAccount4Title",
      a: "faqAccount4Desc",
    },
    {
      q: "faqAccount5Title",
      a: "faqAccount5Desc",
    },
    {
      q: "faqAccount6Title",
      a: (
        <ul>
          <li>
            <Trans i18nKey="faqAccount6Desc" />
          </li>
          <li>
            <Trans i18nKey="faqAccount6Desc2" />
          </li>
        </ul>
      ),
    },
    {
      q: "faqAccount7Title",
      a: "faqAccount7Desc",
    },
    {
      q: "faqAccount8Title",
      a: "faqAccount8Desc",
    },
    {
      q: "faqAccount9Title",
      a: "faqAccount9Desc",
    },
  ],
  transaction: [
    {
      q: "faqTxn1Title",
      a: (
        <>
          <div>
            <Trans i18nKey="faqTxn1Desc" />
            <ul>
              <li>
                <Trans i18nKey="faqTxn1Desc2" />
              </li>
              <li>
                <Trans i18nKey="faqTxn1Desc3" />
              </li>
            </ul>
          </div>
        </>
      ),
    },
    {
      q: "faqTxn2Title",
      a: "faqTxn2Desc",
    },
    {
      q: "faqTxn3Title",
      a: "faqTxn3Desc",
    },
    {
      q: "faqTxn4Title",
      a: (
        <>
          <Trans i18nKey="faqTxn4Desc" />{" "}
          <a
            className="url-link"
            href="https://www.cash-go.com/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans i18nKey="trackTransfer" />
          </a>{" "}
          <Trans i18nKey="faqTxn4Desc2" />
        </>
      ),
    },
    {
      q: "faqTxn5Title",
      a: "faqTxn5Desc",
    },
    {
      q: "faqTxn6Title",
      a: (
        <div>
          <Trans i18nKey="faqTxn6Desc" />
          <ul>
            <li>
              <Trans i18nKey="rocket" />
            </li>
            <li>
              <Trans i18nKey="nagad" />
            </li>
            <li>
              <Trans i18nKey="bkash" />
            </li>
          </ul>
          <div className="bankImgContainer">
            <img src={rocket} alt="Rocket" className="smallBankImg" />
            <img src={nagad} alt="Nagad" className="smallBankImg" />
            <img src={bkash} alt="bKash" className="smallBankImg" />
          </div>
        </div>
      ),
    },
  ],
};
