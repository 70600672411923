import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const TNC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className=""
        style={{
          backgroundColor: "#05754f",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          paddingTop: "80px",
          color: "white",
          height: "180px",
          borderBottomLeftRadius: "256px",
          borderBottomRightRadius: "256px",
        }}
      >
        <div className="" style={{ fontWeight: 700, fontSize: "24px" }}>
          {t("cgTnc")}
        </div>
        <div
          className=""
          style={{ fontWeight: 500, fontSize: "20px", color: "#F2C330" }}
        >
          {t("Effective 22 Apr 2024")}
        </div>
      </div>
      <div
        style={{
          padding: "24px",
          color: "black",
          textAlign: "justify",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <div>{t("tnc1")}</div>
        <div>
          <div>{t("tnc2")}</div>
          <ul style={{ fontSize: "14px" }}>
            <li>{t("tnc2-1")}</li>
            <li>{t("tnc2-2")}</li>
            <li>{t("tnc2-3")}</li>
            <li>{t("tnc2-4")}</li>
            <li>{t("tnc2-5")}</li>
            <li>{t("tnc2-6")}</li>
            <li>{t("tnc2-7")}</li>
            <li>{t("tnc2-8")}</li>
            <li>{t("tnc2-9")}</li>
            <li>{t("tnc2-10")}</li>
            <li>{t("tnc2-11")}</li>
            <li>{t("tnc2-12")}</li>
            <li>{t("tnc2-13")}</li>
            <li>{t("tnc2-14")}</li>
          </ul>
        </div>
        <div>{t("tnc3")}</div>
        <ol style={{ fontSize: "14px" }}>
          <li className="boldTitle">{t("tnc4Title")}</li>
          <div>
            {t("tnc4Desc")} {t("cgEmail")}
          </div>
          <li className="boldTitle">{t("tnc5Title")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc5Desc")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("tnc5Desc2Title")}
            </li>
            <ol>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc5Desc2Desc")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc5Desc2Desc2")}
              </li>
            </ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("tnc5Desc3Title")}
              <ol>
                <li style={{ listStyleType: "lower-roman" }}>
                  {t("tnc5Desc3Desc")}
                </li>
              </ol>
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("tnc5Desc4Title")}
              <ol>
                <li style={{ listStyleType: "lower-roman" }}>
                  {t("tnc5Desc4Desc")}
                </li>
                <li style={{ listStyleType: "lower-roman" }}>
                  {t("tnc5Desc4Desc2")}
                </li>
                <li style={{ listStyleType: "lower-roman" }}>
                  {t("tnc5Desc4Desc3")}
                </li>
              </ol>
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("tnc5Desc5Title")}
            </li>
          </ol>
          <div>{t("tnc5Desc2")}</div>
          <li className="boldTitle">{t("tnc6Title")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc6Desc")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc6Desc2")}</li>
          </ol>
          <li className="boldTitle">{t("tnc7Title")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc7Desc")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc7Desc2")}</li>
            <ol>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc7Desc2Desc")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc7Desc2Desc2")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc7Desc2Desc3")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc7Desc2Desc4")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc7Desc2Desc5")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc7Desc2Desc6")}
              </li>
            </ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc7Desc3")}</li>
            <ol>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc7Desc3Desc")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc7Desc3Desc2")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc7Desc3Desc3")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc7Desc3Desc4")}
              </li>
            </ol>
          </ol>
          <div>{t("tnc7Desc4")}</div>
          <li className="boldTitle">{t("tnc8Title")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc8Desc")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc8Desc2")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc8Desc3")}</li>
            <ol>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc8Desc3Desc")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc8Desc3Desc2")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc8Desc3Desc3")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc8Desc3Desc4")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc8Desc3Desc5")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc8Desc3Desc6")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc8Desc3Desc7")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc8Desc3Desc8")}
              </li>
            </ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc8Desc4")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc8Desc5")}</li>
            <ol>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc8Desc5Desc")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc8Desc5Desc2")}
              </li>
            </ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc8Desc6")}</li>
          </ol>
          <li className="boldTitle">{t("tnc9Title")}</li>
          <div>{t("tnc9Desc")}</div>
          <li className="boldTitle">{t("tnc10Title")}</li>
          <div>{t("tnc10Desc")}</div>
          <li className="boldTitle">{t("tnc11Title")}</li>
          <div>
            {t("tnc11Desc")}
            <Link className="url-link" to={"policy"}>
              {t("clickHere")}
            </Link>
            {t("tnc11Desc2")}
          </div>
          <li className="boldTitle">{t("tnc12Title")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc12Desc")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc12Desc2")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc12Desc3")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc12Desc4")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc12Desc5")}</li>
            <ol>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc12Desc6")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc12Desc7")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("tnc12Desc8")}
              </li>
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  {t("tnc12Desc9")}
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  {t("tnc12Desc10")}
                </li>
              </ol>
            </ol>
          </ol>
          <li className="boldTitle">{t("tnc13Title")}</li>
          <div>{t("tnc13Desc")}</div>
          <li className="boldTitle">{t("tnc14Title")}</li>
          <div>{t("tnc14Desc")}</div>
          <li className="boldTitle">{t("tnc15Title")}</li>
          <div>{t("tnc15Desc")}</div>
          <li className="boldTitle">{t("tnc16Title")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("tnc16Desc")}
              {t("cgEmail")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>{t("tnc16Desc2")}</li>
          </ol>
        </ol>
      </div>
    </div>
  );
};

export default TNC;
