import React from "react";
import {
  affinIcon,
  bkash,
  cimbIcon,
  ezpay,
  hlbbIcon,
  mbbIcon,
  nagad,
  pbbIcon,
  rhbIcon,
  rocket,
} from "../../img";

export const SupportedBanksRow = () => {
  return (
    <div style={{ display: "flex", gap: "8px", flexDirection: "row" }}>
      <div>
        <img src={affinIcon} alt="Affin Bank" className="supportedBank" />
        <img src={rhbIcon} alt="RHB Bank" className="supportedBank" />
      </div>
      <div>
        <img src={pbbIcon} alt="Public Bank" className="supportedBank" />
        <img src={mbbIcon} alt="Maybank" className="supportedBank" />
      </div>
      <div>
        <img src={hlbbIcon} alt="Hong Leong Bank" className="supportedBank" />
        <img src={cimbIcon} alt="CIMB Bank" className="supportedBank" />
      </div>
    </div>
  );
};

export const SupportedPayment = () => {
  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <div>
        <img src={rocket} alt="rocket" className="supportedPayment" />
        <img src={nagad} alt="nagad" className="supportedPayment" />
      </div>
      <div>
        <img src={bkash} alt="bkash" className="supportedPayment" />
        <img src={ezpay} alt="ezpay" className="supportedPayment" />
      </div>
    </div>
  );
};
