import { StarFilled } from "@ant-design/icons";
import { Carousel } from "antd";
import React from "react";
import "../stylings/custom-antd.css";
import { user001, user002, user003, user004, user005, user006 } from "../img";
import UseScreenSize from "./useScreenSize";

const testimonialData = [
  {
    name: "Hassan Najmi",
    username: "@im_hassan",
    title: "High Exchange Rate!",
    desc: "Being student in Malaysia while having family back in Bangladesh, this app has been a lifesaver in helping me manage my finances effectively.",
    img: user001,
    rating: 5,
  },
  {
    name: "Fatimal Nurul",
    username: "@Nurul123",
    title: "Save a lot of my time",
    desc: "I recently used this currency exchange app for the first time, and I’m thoroughly impressed. The rates offered were also incredible competitive.",
    img: user002,
    rating: 5,
  },
  {
    name: "Farhan Saifu",
    username: "@saifuifarhan_",
    title: "Fast Transfer",
    desc: "I have used your fast transfer service to send money to Bangladesh and was impressed by its speed and ease. I am very satisfied and would recommend your service.",
    img: user003,
    rating: 5,
  },
  {
    name: "Shakib Rahman",
    username: "@shkb",
    title: "Excellent Customer Support!",
    desc: "The customer support team is outstanding. They helped me with my issues promptly and professionally. Highly recommend this service!",
    img: user004,
    rating: 5,
  },
  {
    name: "Hasan Mahmud",
    username: "@mahmud_hsn",
    title: "User-Friendly Interface",
    desc: "The app is very easy to use and navigate. I could make transactions effortlessly and the exchange rates are the best I've seen.",
    img: user005,
    rating: 5,
  },
  {
    name: "Khaliq Zubair",
    username: "@khaliq_z",
    title: "Fast and Reliable",
    desc: "I have been using this app for a few months now and I must say, the transactions are always quick and reliable. Never faced any issues.",
    img: user006,
    rating: 5,
  },
];

const RatingDisplay = () => {
  const screenSize = UseScreenSize();
  const isMobile = screenSize?.width < 768;
  return (
    <div
      style={{
        maxWidth: isMobile ? "350px" : "1200px",
      }}
    >
      <Carousel
        className="ratingCarousel"
        infinite={true}
        speed={500}
        slidesToShow={isMobile ? 1 : 3}
        slidesToScroll={1}
        autoplay
        arrows
        dots={false}
      >
        {testimonialData.map((item, index) => (
          <div key={index}>
            <div className="ratingContainer">
              <div className="ratingInnerContainer">
                <img src={item.img} alt="Reviewers" />
                <div className="ratingName">{item.name}</div>
                <div className="ratingUsername">{item.username}</div>
                <div className="ratingTitle">{item.title}</div>
                <div className="ratingDesc">"{item.desc}"</div>
                <div className="ratingStars">
                  {Array.from({ length: item.rating }, (_, i) => (
                    <StarFilled
                      key={i}
                      style={{
                        color: "#F4AC0A",
                        fontSize: "14px",
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default RatingDisplay;
