import React from "react";
import { cgIcon } from "../../img";
import { SupportedBanksRow, SupportedPayment } from "./supportedBanks";
import FooterMenu from "./footerMenuItems";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="footerBg">
        <div className="grid5">
          <div className="itemgrid3">
            <img src={cgIcon} alt="CashGo Logo" width="250px" height="125px" />
            <div className="footerTextDisplay">{t("footerDesc1")}</div>
          </div>
          <div className="itemgrid4">
            <div>
              <div className="orangeTextFooter">{t("content")}</div>
              <FooterMenu />
            </div>
            <div>
              <div className="orangeTextFooter">{t("aboutCg")}</div>
              <div className="footerTextDisplay">{t("footerDesc2")}</div>
            </div>
            <div className="supportBankPayment">
              <div className="bankType">
                <div className="orangeTextFooter">{t("supportBank")}</div>
                <SupportedBanksRow />
              </div>
              <div className="paymentType">
                <div className="orangeTextFooter">{t("supportPayment")}</div>
                <SupportedPayment />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footerEndCopyrightBg">
        <div>{t("copyrightDesc")}</div>
        <div
          style={{
            gap: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textDecorationLine: "underline" }}>
            <Link to={"tnc"}>{t("tnc")}</Link>
          </div>
          <div
            style={{
              borderLeft: "1px solid white",
              borderRight: "1px solid white",
              padding: "0px 8px",
              textDecorationLine: "underline",
            }}
          >
            <Link to={"policy"}>{t("privacyPolicy")}</Link>
          </div>
          <div>{t("247cs")}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
