import React from "react";
import UserGuideDisplay from "../components/guide/userGuide";
import { useTranslation } from "react-i18next";

const UserGuide = () => {
  const { t } = useTranslation();

  return (
    <div className="guideTopicContainer">
      <div className="guideTitle">
        {t("comprehensive")}
        <span style={{ color: "#067449" }}>{t("cash")}</span>
        <span style={{ color: "#F4AC0A" }}>{t("go")}</span> {t("userManual")}
      </div>
      <div className="guideDesc">{t("anyEnquiries")}</div>
      <div style={{ padding: "24px" }}>
        <UserGuideDisplay />
      </div>
    </div>
  );
};
export default UserGuide;
