import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { sportsSbobet } from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const SbobetPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={sportsSbobet} width={200} alt="SBOBET" />
          <div className="info-container">
            <div className="info-title">
              {t("SBOBET Sports Betting | Sportsbook")}
            </div>
            <div className="info-desc">{t("sbobetMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("sbobetTitle1")}</div>
          <div className="info-text-desc">{t("sbobetTitle1Desc1")}</div>
          <div
            className="info-text-desc"
            style={{ textDecorationLine: "underline" }}
          >
            {t("sbobetTitle1Desc2")}
          </div>
          <ol className="info-text-desc">
            <li>{t("sbobetTitle1Desc3")}</li>
            <li>{t("sbobetTitle1Desc4")}</li>
            <li>{t("sbobetTitle1Desc5")}</li>
            <li>{t("sbobetTitle1Desc6")}</li>
            <li>{t("sbobetTitle1Desc7")}</li>
          </ol>
          <div className="info-text-title2">{t("sbobetTitle2")}</div>
          <div className="info-text-desc">{t("sbobetTitle2Desc1")}</div>
          <div className="info-text-desc">
            <ul>
              <li>{t("sbobetTitle2Desc2")}</li>
              <div>{t("sbobetTitle2Desc3")}</div>
              <li>{t("sbobetTitle2Desc4")}</li>
              <div>{t("sbobetTitle2Desc5")}</div>
              <li>{t("sbobetTitle2Desc6")}</li>
              <div>{t("sbobetTitle2Desc7")}</div>
            </ul>
          </div>
          <div className="info-text-title2">{t("sbobetTitle3")}</div>
          <div className="info-text-desc">{t("sbobetTitle3Desc1")}</div>
          <div className="info-text-desc">{t("sbobetTitle3Desc2")}</div>
          <div className="info-text-desc">
            <ul>
              <li>{t("sbobetTitle3Desc3")}</li>
              <li>{t("sbobetTitle3Desc4")}</li>
              <li>{t("sbobetTitle3Desc5")}</li>
              <li>{t("sbobetTitle3Desc6")}</li>
            </ul>
          </div>
          <div className="info-text-title2">{t("sbobetTitle4")}</div>
          <div className="info-text-desc">{t("sbobetTitle4Desc1")}</div>
          <div className="info-text-desc">{t("sbobetTitle4Desc2")}</div>
          <div className="info-text-desc">{t("sbobetTitle4Desc3")}</div>
          <div className="info-text-title2">{t("sbobetTitle5")}</div>
          <div className="info-text-desc">{t("sbobetTitle5Desc1")}</div>
          <div className="info-text-desc">{t("sbobetTitle5Desc2")}</div>
        </div>
      </div>
    </div>
  );
};

export default SbobetPage;
