import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { acewinGame1, acewinGame2, acewinGame3, acewinSlot } from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const AcewinSlotPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={acewinSlot} width={200} alt="Acewin" />
          <div className="info-container">
            <div className="info-title">{t("ACEWIN")}</div>
            <div className="info-desc">{t("acewinMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("acewinTitle1")}</div>
          <div className="info-text-desc">{t("acewinTitle1Desc1")}</div>
          <div className="info-text-desc">{t("acewinTitle1Desc2")}</div>
          <div className="info-text-title2">{t("acewinTitle2")}</div>
          <div className="info-text-desc">{t("acewinTitle2Desc1")}</div>
          <div className="info-text-desc">{t("acewinTitle2Desc2")}</div>
          <div className="info-text-title2">{t("acewinTitle3")}</div>
          <div className="info-text-desc">{t("acewinTitle3Desc1")}</div>
          <div className="info-text-desc" style={{ padding: "0px 30px" }}>
            {t("acewinTitle3Desc2")}
          </div>
          <div className="info-text-desc">{t("acewinTitle3Desc3")}</div>
          <div className="info-text-title">{t("acewinTitle4")}</div>
          <div className="info-text-desc">{t("acewinTitle4Desc")}</div>
          <div className="info-text-title2">{t("acewinTitle5")}</div>
          <div className="info-game">
            <img src={acewinGame1} width={200} alt="Acewin Game 1" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("1. THE GOOD LIFE")}</div>
              <div className="info-text-desc">{t("acewinTitle6Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={acewinGame2} width={200} alt="Acewin Game 2" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("2. FA FA FA 2")}</div>
              <div className="info-text-desc">{t("acewinTitle7Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={acewinGame3} width={200} alt="Acewin Game 3" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("3. HIGH ROAD KING")}</div>
              <div className="info-text-desc">{t("acewinTitle8Desc")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcewinSlotPage;
