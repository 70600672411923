import { MenuOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cgIcon2 } from "../../img";
import UseScreenSize from "../useScreenSize";
import LangPicker from "./languagePick";
import MenuDrawer from "./menuDrawer";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

const { Option } = Select;

const headerMenuItems = [
  { path: "/promotion", label: "promo" },
  { path: "/download", label: "download" },
  { path: "/recruitment", label: "recruit" },
  { path: "/faq", label: "faq" },
  { path: "/guide", label: "guide" },
  { path: "/lottery", label: "lottery" },
  { path: "/sport", label: "sport" },
  { path: "/news", label: "news" },
  {
    label: "game",
    subPages: [
      { path: "/slot", label: "slot" },
      { path: "/casino", label: "casino" },
      { path: "/sportsbook", label: "sport2" },
      { path: "/4d-lottery", label: "lottery2" },
      { path: "/esports", label: "esports" },
      { path: "/horseracing", label: "horseracing" },
    ],
  },
];

const HeaderMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const screenSize = UseScreenSize();
  const isMobile = screenSize?.width < 768;
  const { t } = useTranslation();

  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleDrawerVisible = () => {
    setDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setDrawerVisible(false);
  };

  const handleSelectChange = (value) => {
    navigate(value);
  };

  return (
    <div className="headermenu">
      <div
        className={`logocontainer ${location.pathname === "/" ? "active" : ""}`}
      >
        <Link to="/">
          <img src={cgIcon2} alt="CashGo Logo" width="100px" height="50px" />
        </Link>
      </div>
      {!isMobile && (
        <>
          {headerMenuItems.map((item) => (
            <div
              key={item.path}
              className={`menuitems ${
                location.pathname === item.path ? "active" : ""
              }`}
            >
              {item.subPages ? (
                <Select
                  className="custom-game-select"
                  onChange={handleSelectChange}
                  value={t(item.label)}
                  dropdownStyle={{ fontSize: "10px", width: "120px" }}
                >
                  {item.subPages.map((subItem) => (
                    <Option key={subItem.path} value={subItem.path}>
                      {t(subItem.label)}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Link to={item.path}>{t(item.label)}</Link>
              )}
            </div>
          ))}
          <LangPicker />
        </>
      )}
      <div className="menubuttoncontainer">
        <div className="registerBtn">
          <Link to="/register">{t("register")}</Link>
        </div>
        <div className="loginBtn">
          <Link to="/login">{t("login")}</Link>
        </div>
        {isMobile && (
          <>
            <MenuOutlined
              className="burgerMenuBtn"
              style={{ fontSize: "16px" }}
              onClick={handleDrawerVisible}
            />
            <MenuDrawer
              open={drawerVisible}
              close={handleDrawerClose}
              menuItems={headerMenuItems}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderMenu;
