import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  codGame,
  csgoGame,
  dota2Game,
  e1sports,
  hearthStoneGame,
  lolGame,
  smiteGame,
} from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const E1SportPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={e1sports} width={200} alt="E1 Sports" />
          <div className="info-container">
            <div className="info-title">{t("E1 SPORT")}</div>
            <div className="info-desc">{t("e1sportsMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title2">{t("e1sportsTitle1")}</div>
          <div className="info-text-desc">{t("e1sportsTitle1Desc")}</div>
          <div className="info-text-title2">{t("e1sportsTitle2")}</div>
          <div className="info-text-desc">{t("e1sportsTitle2Desc1")}</div>
          <div className="info-text-desc">{t("e1sportsTitle2Desc2")}</div>
          <div className="info-text-title2">{t("e1sportsTitle3")}</div>
          <div className="info-text-desc">{t("e1sportsTitle3Desc1")}</div>
          <ol className="info-text-desc">
            <li>{t("e1sportsTitle3Desc2")}</li>
            <li>{t("e1sportsTitle3Desc3")}</li>
            <li>{t("e1sportsTitle3Desc4")}</li>
          </ol>
          <div className="info-text-title2">{t("e1sportsTitle4")}</div>
          <div className="info-text-desc">{t("e1sportsTitle4Desc1")}</div>
          <ol className="info-text-desc">
            <li>{t("e1sportsTitle4Desc2")}</li>
            <li>{t("e1sportsTitle4Desc3")}</li>
            <li>{t("e1sportsTitle4Desc4")}</li>
            <li>{t("e1sportsTitle4Desc5")}</li>
            <li>{t("e1sportsTitle4Desc6")}</li>
          </ol>
          <div className="info-text-desc">{t("e1sportsTitle4Desc7")}</div>
          <div className="info-text-desc">{t("e1sportsTitle4Desc8")}</div>
          <div className="info-text-title2">{t("e1sportsTitle5")}</div>
          <div className="game-img-container">
            <div className="game-img-display2">
              <div>
                <img src={lolGame} alt="LEAGUE OF LEGENDS" />
                <div>{t("LEAGUE OF LEGENDS")}</div>
              </div>
              <div>
                <img src={dota2Game} alt="DOTA 2" />
                <div>{t("DOTA 2")}</div>
              </div>
              <div>
                <img src={csgoGame} alt="COUNTER-STRIKE: GLOBAL OFFENSIVE" />
                <div>{t("COUNTER-STRIKE: GLOBAL OFFENSIVE")}</div>
              </div>
              <div>
                <img
                  src={hearthStoneGame}
                  alt="HEARTHSTONE: HEROES OF WARCRAFT"
                />
                <div>{t("HEARTHSTONE: HEROES OF WARCRAFT")}</div>
              </div>
              <div>
                <img src={codGame} alt="CALL OF DUTY" />
                <div>{t("CALL OF DUTY")}</div>
              </div>
              <div>
                <img src={smiteGame} alt="SMITE" />
                <div>{t("SMITE")}</div>
              </div>
            </div>
          </div>
          <div className="info-text-desc">{t("e1sportsTitle6Desc1")}</div>
          <div className="info-text-desc">{t("e1sportsTitle6Desc2")}</div>
          <div className="info-text-desc">{t("e1sportsTitle6Desc3")}</div>
          <div className="info-text-title2">{t("e1sportsTitle6")}</div>
          <div className="info-game">
            <img src={csgoGame} width={200} alt="CSGO" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("e1sportsTitle7")}</div>
              <div className="info-text-desc">{t("e1sportsTitle7Desc1")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={dota2Game} width={200} alt="Dota2" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("e1sportsTitle8")}</div>
              <div className="info-text-desc">{t("e1sportsTitle8Desc1")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={codGame} width={200} alt="COD" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("e1sportsTitle9")}</div>
              <div className="info-text-desc">{t("e1sportsTitle9Desc1")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={lolGame} width={200} alt="LOL" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("e1sportsTitle10")}</div>
              <div className="info-text-desc">{t("e1sportsTitle10Desc")}</div>
            </div>
          </div>
          <div className="info-text-desc">{t("e1sportsTitle10Desc2")}</div>
          <div className="info-text-title2">{t("e1sportsTitle11")}</div>
          <div className="info-text-desc">{t("e1sportsTitle11Desc1")}</div>
          <div className="info-text-title2">{t("e1sportsTitle12")}</div>
          <div className="info-text-desc">{t("e1sportsTitle12Desc1")}</div>
          <div className="info-text-title2">{t("e1sportsTitle13")}</div>
          <div className="info-text-desc">{t("e1sportsTitle13Desc1")}</div>
          <div className="info-text-title2">{t("e1sportsTitle14")}</div>
          <div className="info-text-desc">{t("e1sportsTitle14Desc1")}</div>
          <div className="info-text-title2">{t("e1sportsTitle15")}</div>
          <div className="info-text-desc">{t("e1sportsTitle15Desc1")}</div>
        </div>
      </div>
    </div>
  );
};

export default E1SportPage;
