import React from "react";
import { useTranslation } from "react-i18next";
import "../stylings/custom-antd.css";
import "../stylings/faqStyling.css";
import {
  aeSexyCasino,
  agCasino,
  bgCasino,
  casinoMobileBanner,
  casinoPageBanner,
  hotroadCasino,
} from "../img";
import { Link } from "react-router-dom";
import UseScreenSize from "../components/useScreenSize";

const CasinoGamePage = () => {
  const { t } = useTranslation();
  const screenSize = UseScreenSize();
  const isMobile = screenSize?.width < 768;

  return (
    <div className="game-page-display">
      <div>
        {isMobile ? (
          <img src={casinoMobileBanner} alt="Casino Banner" />
        ) : (
          <img src={casinoPageBanner} alt="Casino Banner" />
        )}
      </div>
      <div className="game-img-container">
        <div className="game-img-display">
          <Link to={"/ae-sexy"}>
            <div className="game-title">
              <img src={aeSexyCasino} alt="AE Sexy" />
              <div>{t("AE SEXY")}</div>
            </div>
          </Link>
          <Link to={"/hot-road"}>
            <div className="game-title">
              <img src={hotroadCasino} alt="Hot Road" />
              <div>{t("HOT ROAD")}</div>
            </div>
          </Link>
          <Link to={"/big-gaming"}>
            <div className="game-title">
              <img src={bgCasino} alt="Big Gaming" />
              <div>{t("BIG GAMING")}</div>
            </div>
          </Link>
          <Link to={"/asia-gaming"}>
            <div className="game-title">
              <img src={agCasino} alt="Asia Gaming" />
              <div>{t("ASIA GAMING")}</div>
            </div>
          </Link>
        </div>
      </div>
      <div className="text-container">
        <div className="text-display">
          <div className="text-display-container">
            <div className="text-title">{t("casinoPageTitle1")}</div>
            <div className="text-paragraph">
              {t("casinoPageTitle1Desc1")}
              <Link to={"/"} className="url-link2">
                {t("cashgoCasino")}
              </Link>
              <span>{t("casinoPageTitle1Desc1-2")}</span>
            </div>
            <div className="text-paragraph">{t("casinoPageTitle1Desc2")}</div>
            <div className="text-title">{t("casinoPageTitle2")}</div>
            <div className="text-paragraph">
              {t("casinoPageTitle2Desc1")}
              <Link to={"/login"} className="url-link2">
                {t("loginCg")}
              </Link>
              <span>{t("casinoPageTitle2Desc2")}</span>
            </div>
            <div className="text-title">{t("casinoPageTitle3")}</div>
            <div className="text-paragraph">{t("casinoPageTitle3Desc")}</div>
            <div className="text-paragraph">
              {t("casinoPageTitle3Desc2")}
              <Link to={"/"} className="url-link2">
                {t("cashGoLink")}
              </Link>
              <span>{t("casinoPageTitle3Desc2-2")}</span>
            </div>
            <div className="text-paragraph">{t("casinoPageTitle3Desc3")}</div>
          </div>
          <div className="text-display-container">
            <div className="text-title">{t("casinoPageTitle4")}</div>
            <div className="text-paragraph">
              <Link to={"/casino"} className="url-link2">
                {t("cashGoLiveCasino")}
              </Link>
              {t("casinoPageTitle4Desc")}
            </div>
            <div className="text-title">{t("casinoPageTitle5")}</div>
            <div className="text-paragraph">{t("casinoPageTitle5Desc")}</div>
            <div style={{ fontSize: "12px", padding: "0px 40px" }}>
              {t("casinoPageTitle5Desc2")}
            </div>
            <div className="text-paragraph">{t("casinoPageTitle5Desc3")}</div>
            <div className="text-title">{t("casinoPageTitle6")}</div>
            <div className="text-paragraph">{t("casinoPageTitle6Desc")}</div>
            <div className="text-title">{t("casinoPageTitle7")}</div>
            <div className="text-paragraph">{t("casinoPageTitle7Desc")}</div>
          </div>
          <div className="text-display-container">
            <div className="text-title">{t("casinoPageTitle8")}</div>
            <div className="text-paragraph">{t("casinoPageTitle8Desc")}</div>
            <div className="text-title">{t("casinoPageTitle9")}</div>
            <div className="text-paragraph">{t("casinoPageTitle9Desc")}</div>
            <div className="text-title">{t("casinoPageTitle10")}</div>
            <div className="text-paragraph">
              {t("casinoPageTitle10Desc")}
              <Link to={"/download"} className="url-link2">
                {t("downloadApp")}
              </Link>
              <span>{t("casinoPageTitle10Desc2")}</span>
            </div>
            <div className="text-title">{t("casinoPageTitle11")}</div>
            <div className="text-paragraph">{t("casinoPageTitle11Desc")}</div>
            <div className="text-paragraph">
              <Link to={"/register"} className="url-link2">
                {t("registerCashGo")}
              </Link>
              {t("casinoPageTitle11Desc2")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoGamePage;
