import React from "react";
import { useTranslation } from "react-i18next";
import { e1sports, esportBanner, esportsMobileBanner } from "../img";
import "../stylings/custom-antd.css";
import "../stylings/faqStyling.css";
import { Link } from "react-router-dom";
import UseScreenSize from "../components/useScreenSize";

const EsportsGamePage = () => {
  const { t } = useTranslation();
  const screenSize = UseScreenSize();
  const isMobile = screenSize?.width < 768;

  return (
    <div className="game-page-display">
      <div>
        {isMobile ? (
          <img src={esportsMobileBanner} alt="E1 Banner" />
        ) : (
          <img src={esportBanner} alt="E1 Banner" style={{ width: "100vw" }} />
        )}
      </div>
      <div className="game-img-container">
        <div className="single-game-display">
          <Link to={"/e1-sport"}>
            <div className="game-title">
              <img src={e1sports} alt="E1 Sports" width={250} />
              <div>{t("E1 SPORTS")}</div>
            </div>
          </Link>
        </div>
      </div>
      <div className="text-container">
        <div className="text-display">
          <div className="text-display-container">
            <div className="text-title">{t("esportsPageTitle")}</div>
            <div className="text-paragraph">
              {t("esportsPageTitleDesc1")}
              <Link to={"/"} className="url-link2">
                {t("cashgoCasino")}
              </Link>
              <span>{t("esportsPageTitleDesc1-2")}</span>
            </div>
            <div className="text-paragraph">{t("esportsPageTitleDesc2")}</div>
            <div className="text-paragraph">{t("esportsPageTitleDesc3")}</div>
            <div className="text-title">{t("esportsPageTitle2")}</div>
            <div className="text-paragraph">{t("esportsPageTitle2Desc1")}</div>
          </div>
          <div className="text-display-container">
            <div className="text-title">{t("esportsPageTitle3")}</div>
            <div className="text-paragraph">
              {t("esportsPageTitle3Desc1")}
              <Link to={"/login"} className="url-link2">
                {t("loginCg")}
              </Link>
              <span>{t("esportsPageTitle3Desc1-2")}</span>
            </div>
            <div className="text-title">{t("esportsPageTitle4")}</div>
            <div className="text-paragraph">{t("esportsPageTitle4Desc1")}</div>
            <div className="text-paragraph">
              <Link to={"/download"} className="url-link2">
                {t("downloadCg")}
              </Link>
              {t("esportsPageTitle4Desc2")}
            </div>
          </div>
          <div className="text-display-container">
            <div className="text-title">{t("esportsPageTitle5")}</div>
            <div className="text-paragraph">
              {t("esportsPageTitle5Desc1")}
              <Link to={"/register"} className="url-link2">
                {t("registerCashGo")}
              </Link>
              <span>{t("esportsPageTitle5Desc1-2")}</span>
            </div>
            <div className="text-paragraph">{t("esportsPageTitle5Desc2")}</div>
            <div className="text-paragraph">
              {t("esportsPageTitle5Desc3")}
              <Link to={"/e1-sport"} className="url-link2">
                {t("e1sport")}
              </Link>
              <span>{t("esportsPageTitle5Desc3-2")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EsportsGamePage;
