import { ConfigProvider, Select } from "antd";
import enUS from "antd/lib/locale/en_US";
import msMY from "antd/lib/locale/ms_MY";
import zhCN from "antd/lib/locale/zh_CN";
import React, { useEffect, useState } from "react";
import { bnIcon, cnIcon, enIcon, mlyIcon } from "../../img";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const LANG_OPTIONS = [
  {
    value: "en",
    label: "en",
    locale: enUS,
    country: enIcon,
  },
  {
    value: "cn",
    label: "cn",
    locale: zhCN,
    country: cnIcon,
  },
  {
    value: "my",
    label: "my",
    locale: msMY,
    country: mlyIcon,
  },
  {
    value: "bn",
    label: "bn",
    locale: msMY,
    country: bnIcon,
  },
];

const LangPicker = ({ mobile, onLanguageChange }) => {
  const { i18n, t } = useTranslation();
  const [lang, setLang] = useState("en");
  const [locale, setLocale] = useState("en");
  const mobileView = mobile || false;

  useEffect(() => {
    const selectedLang = LANG_OPTIONS.find((option) => option.value === lang);
    if (selectedLang) {
      setLocale(selectedLang.locale);
    }
  }, [lang]);

  const handleLangChange = (value) => {
    const selectedLang = LANG_OPTIONS.find((option) => option.value === value);
    if (selectedLang) {
      if (mobileView) {
        onLanguageChange(true);
      }
      setLang(value);
      i18n.changeLanguage(value);
    }
  };

  return (
    <ConfigProvider locale={locale}>
      <div>
        <Select
          className="selectDropdown"
          value={lang}
          onChange={handleLangChange}
        >
          {LANG_OPTIONS.map((option) => (
            <Option key={option.value} value={option.value}>
              <div className="langDisplay">
                <img
                  src={option.country}
                  alt={t(option.label)}
                  className="countryLogo"
                />
                {t(option.label)}
              </div>
            </Option>
          ))}
        </Select>
      </div>
    </ConfigProvider>
  );
};

export default LangPicker;
