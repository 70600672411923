import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { sports9wicket } from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const Wicket9Page = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={sports9wicket} width={200} alt="9 WICKETS" />
          <div className="info-container">
            <div className="info-title">{t("9 WICKETS")}</div>
            <div className="info-desc">{t("9wicketMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("9wicketTitle1")}</div>
          <div className="info-text-desc">{t("9wicketTitle1Desc")}</div>
          <div className="info-text-title2">{t("9wicketTitle2")}</div>
          <div className="info-text-desc">{t("9wicketTitle2Desc")}</div>
          <div className="info-text-title2">{t("9wicketTitle3")}</div>
          <div className="info-text-desc">{t("9wicketTitle3Desc")}</div>
          <div className="info-text-title2">{t("9wicketTitle4")}</div>
          <div className="info-text-desc">{t("9wicketTitle4Desc")}</div>
          <div className="info-text-title">{t("9wicketTitle5")}</div>
          <div className="info-text-desc">{t("9wicketTitle5Desc")}</div>
          <div className="info-text-title2">{t("9wicketTitle6")}</div>
          <div className="info-text-desc">
            {t("9wicketTitle6Desc")}
            <div className="info-text-title2">{t("9wicketTitle7")}</div>
            <div className="info-text-desc">{t("9wicketTitle7Desc")}</div>
            <div className="info-text-title2">{t("9wicketTitle8")}</div>
            <div className="info-text-desc">{t("9wicketTitle8Desc")}</div>
            <div className="info-text-title">{t("9wicketTitle9")}</div>
            <div className="info-text-desc">{t("9wicketTitle9Desc")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wicket9Page;
