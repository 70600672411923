import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { agCasino, agGameimg } from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const AsiaGamingCasinoPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={agCasino} width={200} alt="Asia Gaming" />
          <div className="info-container">
            <div className="info-title">{t("ASIA GAMING")}</div>
            <div className="info-desc">{t("asiaGamingMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("asiaGamingTitle1")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle1Desc1")}</div>
          <ul className="info-text-desc">
            <li>{t("SQUEEZE BACCARAT")}</li>
            <li>{t("VIP PRIVATE ROOM")}</li>
            <li>{t("PRE-DEALING 6 CARDS")}</li>
            <li>{t("INTERACTIVE BID BACCARAT")}</li>
          </ul>
          <div className="info-text-desc">{t("asiaGamingTitle1Desc2")}</div>
          <div className="info-text-title2">{t("asiaGamingTitle2")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle2Desc1")}</div>
          <ol className="info-text-desc">
            <li style={{ listStyleType: "lower-alpha" }}>{t("VJ Hall")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>{t("AGQ")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>{t("AG EURO")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>{t("AGIN")}</li>
          </ol>
          <div className="info-text-desc">{t("asiaGamingTitle2Desc2")}</div>
          <div className="info-text-title2">{t("VJ HALL")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle3Desc")}</div>
          <div className="info-text-title2">{t("eGames")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle4Desc1")}</div>
          <img src={agGameimg} alt="Asia Gaming Image" />
          <div className="info-text-desc">{t("asiaGamingTitle4Desc2")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle4Desc3")}</div>
          <ul className="info-text-desc">
            <li>{t("INSURANCE BACCARAT")}</li>
            <li>{t("BACCARAT")}</li>
            <li>{t("ROULETTE")}</li>
            <li>{t("DRAGON TIGER")}</li>
            <li>{t("SIC BO")}</li>
          </ul>
          <div className="info-text-title2">{t("asiaGamingTitle5")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle5Desc")}</div>
          <div className="info-text-title2">{t("BACCARAT")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle6Desc1")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle6Desc2")}</div>
          <ul className="info-text-desc">
            <li>{t("CLASSIC BACCARAT")}</li>
            <li>{t("LIVE DEALER BACCARAT")}</li>
            <li>{t("VIP BACCARAT")}</li>
            <li>{t("BID BACCARAT")}</li>
            <li>{t("INSURANCE BACCARAT")}</li>
          </ul>
          <div className="info-text-desc">{t("asiaGamingTitle6Desc3")}</div>
          <ul className="info-text-desc">
            <li>{t("asiaGamingTitle6Desc4")}</li>
            <li>{t("asiaGamingTitle6Desc5")}</li>
            <li>{t("asiaGamingTitle6Desc6")}</li>
            <li>{t("asiaGamingTitle6Desc7")}</li>
          </ul>
          <div className="info-text-desc">{t("asiaGamingTitle6Desc8")}</div>
          <div className="info-text-title2">{t("DRAGON TIGER")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle7Desc1")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle7Desc2")}</div>
          <div className="info-text-title2">{t("SIC BO")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle8Desc1")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle8Desc2")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle8Desc3")}</div>
          <div className="info-text-title2">{t("BULL BULL")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle9Desc")}</div>
          <div className="info-text-title2">{t("asiaGamingTitle10")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle10Desc")}</div>
          <div className="info-text-title2">{t("asiaGamingTitle11")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle11Desc1")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle11Desc2")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle11Desc3")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle11Desc4")}</div>
          <div className="info-text-title2">{t("asiaGamingTitle12")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle12Desc1")}</div>
          <ul className="info-text-desc">
            <li>{t("Debit cards")}</li>
            <li>{t("Credit cards")}</li>
            <li>{t("E-wallets")}</li>
            <li>{t("Mobile payments")}</li>
            <li>{t("Bank transfers")}</li>
            <li>{t("Help2Pay")}</li>
            <li>{t("EeziePay")}</li>
          </ul>
          <div className="info-text-title2">{t("asiaGamingTitle13")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle13Desc1")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle13Desc2")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle13Desc3")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle13Desc4")}</div>
          <div className="info-text-title2">{t("asiaGamingTitle14")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle14Desc1")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle14Desc2")}</div>
          <div className="info-text-title2">{t("asiaGamingTitle15")}</div>
          <div className="info-text-desc">{t("asiaGamingTitle15Desc")}</div>
        </div>
      </div>
    </div>
  );
};

export default AsiaGamingCasinoPage;
