import React from "react";
import { useTranslation } from "react-i18next";
import "../stylings/custom-antd.css";
import "../stylings/faqStyling.css";
import { ekorLottery, lotteryMobileBanner, lotteryPageBanner } from "../img";
import { Link } from "react-router-dom";
import UseScreenSize from "../components/useScreenSize";

const LotteryGamePage = () => {
  const { t } = useTranslation();
  const screenSize = UseScreenSize();
  const isMobile = screenSize?.width < 768;

  return (
    <div className="game-page-display">
      <div>
        {isMobile ? (
          <img src={lotteryMobileBanner} alt="4D Lottery" />
        ) : (
          <img src={lotteryPageBanner} alt="4D Lottery" />
        )}
      </div>
      <div className="game-img-container">
        <div className="single-game-display">
          <Link to={"/ekor"}>
            <div className="game-title">
              <img src={ekorLottery} alt="EKOR" width={250} />
              <div>{t("EKOR")}</div>
            </div>
          </Link>
        </div>
      </div>
      <div className="text-container">
        <div className="text-display">
          <div className="text-display-container">
            <div className="text-title">{t("lotteryPageTitle")}</div>
            <div className="text-paragraph">
              {t("lotteryPageTitleDesc1")}
              <Link to={"/"} className="url-link2">
                {t("cashgoCasino")}
              </Link>
              {t("lotteryPageTitleDesc1-2")}
            </div>
            <div className="text-paragraph">{t("lotteryPageTitleDesc2")}</div>
            <div className="text-title">{t("lotteryPageTitle2")}</div>
            <div className="text-paragraph">{t("lotteryPageTitle2Desc1")}</div>
            <div className="text-paragraph">{t("lotteryPageTitle2Desc2")}</div>
          </div>
          <div className="text-display-container">
            <div className="text-title">{t("lotteryPageTitle3")}</div>
            <div className="text-paragraph">{t("lotteryPageTitle3Desc1")}</div>
            <div className="text-paragraph">
              {t("lotteryPageTitle3Desc2")}
              <Link to={"/register"} className="url-link2">
                {t("registerCashGo")}
              </Link>
              {t("lotteryPageTitle3Desc2-2")}
            </div>
            <div className="text-title">{t("lotteryPageTitle4")}</div>
            <div className="text-paragraph">{t("lotteryPageTitle4Desc1")}</div>
            <div className="text-title">{t("lotteryPageTitle5")}</div>
            <div className="text-paragraph">
              {t("lotteryPageTitle5Desc1")}
              <Link to={"/login"} className="url-link2">
                {t("loginCg")}
              </Link>
              {t("lotteryPageTitle5Desc1-2")}
            </div>
          </div>
          <div className="text-display-container">
            <div className="text-title">{t("lotteryPageTitle6")}</div>
            <div className="text-paragraph">
              {t("lotteryPageTitle6Desc1")}
              <Link to={"/download"} className="url-link2">
                {t("downloadCg")}
              </Link>
              {t("lotteryPageTitle6Desc1-2")}
            </div>
            <div className="text-title">{t("lotteryPageTitle7")}</div>
            <div className="text-paragraph">{t("lotteryPageTitle7Desc1")}</div>
            <div className="text-paragraph">{t("lotteryPageTitle7Desc2")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LotteryGamePage;
