import { Modal } from "antd";
import React, { useState } from "react";
import { promoContent } from "./promoData";
import PromoItem from "./promoItem";
import { useTranslation } from "react-i18next";

const PromoContent = ({ img, title1, title2, desc }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalPopUp = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const translateContent = (content) => {
    if (typeof content === "string") {
      return t(content);
    } else {
      // Render JSX content directly
      return content;
    }
  };

  return (
    <>
      {promoContent.map((item, index) => (
        <PromoItem
          key={index}
          img={item.img}
          title1={t(item.title1)}
          title2={t(item.title2)}
          onClick={() => handleModalPopUp(item)}
        />
      ))}

      <Modal
        title={t(modalContent.title1)}
        centered
        open={showModal}
        onCancel={handleCancel}
        footer={null}
      >
        <img src={modalContent.img} alt={t(modalContent.title1)} />
        <div className="modalContainer">
          {translateContent(modalContent.desc)}
        </div>
      </Modal>
    </>
  );
};

export default PromoContent;
