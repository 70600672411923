import React from "react";
import { cashGoQr } from "../img";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="outerIframeContainer">
        <div className="iframe-container">
          <iframe
            src="https://cashgo88.com/auth/"
            title="Register"
            width="100%"
            height="100vh"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div style={{ padding: "4px" }}>
        <div className="referralQr">
          <img src={cashGoQr} alt="CashGo Referral QR" />
          <div className="referralQrText">
            <div style={{ color: "#067449", fontWeight: 500 }}>
              {t("scanQr")}
            </div>
            <div>{t("enterCode")}</div>
            <div>{t("referrerCode")}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
