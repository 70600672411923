import React from "react";
import "../stylings/gamepage.css";
import {
  lucky365games1,
  lucky365games10,
  lucky365games11,
  lucky365games12,
  lucky365games13,
  lucky365games14,
  lucky365games15,
  lucky365games16,
  lucky365games17,
  lucky365games18,
  lucky365games19,
  lucky365games2,
  lucky365games20,
  lucky365games21,
  lucky365games22,
  lucky365games23,
  lucky365games24,
  lucky365games25,
  lucky365games26,
  lucky365games27,
  lucky365games28,
  lucky365games29,
  lucky365games3,
  lucky365games30,
  lucky365games31,
  lucky365games32,
  lucky365games33,
  lucky365games34,
  lucky365games35,
  lucky365games36,
  lucky365games37,
  lucky365games38,
  lucky365games39,
  lucky365games4,
  lucky365games40,
  lucky365games41,
  lucky365games42,
  lucky365games43,
  lucky365games44,
  lucky365games45,
  lucky365games46,
  lucky365games47,
  lucky365games48,
  lucky365games49,
  lucky365games5,
  lucky365games50,
  lucky365games51,
  lucky365games52,
  lucky365games53,
  lucky365games54,
  lucky365games55,
  lucky365games56,
  lucky365games57,
  lucky365games58,
  lucky365games59,
  lucky365games6,
  lucky365games60,
  lucky365games61,
  lucky365games62,
  lucky365games63,
  lucky365games7,
  lucky365games8,
  lucky365games9,
} from "../img/lucky365";

const Lucky365GamesImg = () => {
  return (
    <div className="games-img-container">
      <div className="games-img-render">
        <img src={lucky365games1} alt="Lucky 365 Game 1" />
        <img src={lucky365games2} alt="Lucky 365 Game 2" />
        <img src={lucky365games3} alt="Lucky 365 Game 3" />
        <img src={lucky365games4} alt="Lucky 365 Game 4" />
        <img src={lucky365games5} alt="Lucky 365 Game 5" />
        <img src={lucky365games6} alt="Lucky 365 Game 6" />
        <img src={lucky365games7} alt="Lucky 365 Game 7" />
        <img src={lucky365games8} alt="Lucky 365 Game 8" />
        <img src={lucky365games9} alt="Lucky 365 Game 9" />
        <img src={lucky365games10} alt="Lucky 365 Game 10" />
        <img src={lucky365games11} alt="Lucky 365 Game 11" />
        <img src={lucky365games12} alt="Lucky 365 Game 12" />
        <img src={lucky365games13} alt="Lucky 365 Game 13" />
        <img src={lucky365games14} alt="Lucky 365 Game 14" />
        <img src={lucky365games15} alt="Lucky 365 Game 15" />
        <img src={lucky365games16} alt="Lucky 365 Game 16" />
        <img src={lucky365games17} alt="Lucky 365 Game 17" />
        <img src={lucky365games18} alt="Lucky 365 Game 18" />
        <img src={lucky365games19} alt="Lucky 365 Game 19" />
        <img src={lucky365games20} alt="Lucky 365 Game 20" />
        <img src={lucky365games21} alt="Lucky 365 Game 21" />
        <img src={lucky365games22} alt="Lucky 365 Game 22" />
        <img src={lucky365games23} alt="Lucky 365 Game 23" />
        <img src={lucky365games24} alt="Lucky 365 Game 24" />
        <img src={lucky365games25} alt="Lucky 365 Game 25" />
        <img src={lucky365games26} alt="Lucky 365 Game 26" />
        <img src={lucky365games27} alt="Lucky 365 Game 27" />
        <img src={lucky365games28} alt="Lucky 365 Game 28" />
        <img src={lucky365games29} alt="Lucky 365 Game 29" />
        <img src={lucky365games30} alt="Lucky 365 Game 30" />
        <img src={lucky365games31} alt="Lucky 365 Game 31" />
        <img src={lucky365games32} alt="Lucky 365 Game 32" />
        <img src={lucky365games33} alt="Lucky 365 Game 33" />
        <img src={lucky365games34} alt="Lucky 365 Game 34" />
        <img src={lucky365games35} alt="Lucky 365 Game 35" />
        <img src={lucky365games36} alt="Lucky 365 Game 36" />
        <img src={lucky365games37} alt="Lucky 365 Game 37" />
        <img src={lucky365games38} alt="Lucky 365 Game 38" />
        <img src={lucky365games39} alt="Lucky 365 Game 39" />
        <img src={lucky365games40} alt="Lucky 365 Game 40" />
        <img src={lucky365games41} alt="Lucky 365 Game 41" />
        <img src={lucky365games42} alt="Lucky 365 Game 42" />
        <img src={lucky365games43} alt="Lucky 365 Game 43" />
        <img src={lucky365games44} alt="Lucky 365 Game 44" />
        <img src={lucky365games45} alt="Lucky 365 Game 45" />
        <img src={lucky365games46} alt="Lucky 365 Game 46" />
        <img src={lucky365games47} alt="Lucky 365 Game 47" />
        <img src={lucky365games48} alt="Lucky 365 Game 48" />
        <img src={lucky365games49} alt="Lucky 365 Game 49" />
        <img src={lucky365games50} alt="Lucky 365 Game 50" />
        <img src={lucky365games51} alt="Lucky 365 Game 51" />
        <img src={lucky365games52} alt="Lucky 365 Game 52" />
        <img src={lucky365games53} alt="Lucky 365 Game 53" />
        <img src={lucky365games54} alt="Lucky 365 Game 54" />
        <img src={lucky365games55} alt="Lucky 365 Game 55" />
        <img src={lucky365games56} alt="Lucky 365 Game 56" />
        <img src={lucky365games57} alt="Lucky 365 Game 57" />
        <img src={lucky365games58} alt="Lucky 365 Game 58" />
        <img src={lucky365games59} alt="Lucky 365 Game 59" />
        <img src={lucky365games60} alt="Lucky 365 Game 60" />
        <img src={lucky365games61} alt="Lucky 365 Game 61" />
        <img src={lucky365games62} alt="Lucky 365 Game 62" />
        <img src={lucky365games63} alt="Lucky 365 Game 63" />
      </div>
    </div>
  );
};

export default Lucky365GamesImg;
