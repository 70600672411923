import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { mega888Game } from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const Mega888SlotPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={mega888Game} width={200} alt="Mega888" />
          <div className="info-container">
            <div className="info-title">{t("MEGA888")}</div>
            <div className="info-desc">{t("mega888MainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("mega888Title1")}</div>
          <div className="info-text-desc">{t("mega888Title1Desc")}</div>
          <div className="info-text-title">{t("mega888Title2")}</div>
          <div className="info-text-desc">{t("mega888Title2Desc1")}</div>
          <div className="info-text-desc">{t("mega888Title2Desc2")}</div>
          <div className="info-text-title">{t("mega888Title3")}</div>
          <div className="info-text-desc">{t("mega888Title3Desc")}</div>
          <div className="info-text-title">{t("mega888Title4")}</div>
          <div className="info-text-desc">{t("mega888Title4Desc")}</div>
          <div className="info-text-title">{t("mega888Title5")}</div>
          <div className="info-text-desc">{t("mega888Title5Desc1")}</div>
          <div className="info-text-desc">{t("mega888Title5Desc2")}</div>
          <div className="info-text-title">{t("mega888Title6")}</div>
          <div className="info-text-desc">{t("mega888Title6Desc1")}</div>
          <div className="info-text-desc">{t("mega888Title6Desc2")}</div>
          <div className="info-text-title">{t("mega888Title7")}</div>
          <div className="info-text-desc">{t("mega888Title7Desc")}</div>
          <div className="info-text-title">{t("mega888Title8")}</div>
          <div className="info-text-desc">{t("mega888Title8Desc")}</div>
        </div>
      </div>
    </div>
  );
};

export default Mega888SlotPage;
