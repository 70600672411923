import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { cockFightingPng, sportsSv388 } from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const Sv388Page = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={sportsSv388} width={200} alt="SV388" />
          <div className="info-container">
            <div className="info-title">{t("SV388 Casino")}</div>
            <div className="info-desc">{t("sv388MainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("sv388Title1")}</div>
          <div className="info-text-desc">{t("sv388Title1Desc1")}</div>
          <div className="info-text-desc">{t("sv388Title1Desc2")}</div>
          <div className="info-text-desc">{t("sv388Title1Desc3")}</div>
          <div className="info-text-title2">{t("sv388Title2")}</div>
          <div className="info-text-desc">{t("sv388Title2Desc1")}</div>
          <div className="info-text-desc">
            <ol>
              <li>{t("sv388Title2Desc2")}</li>
              <div>{t("sv388Title2Desc3")}</div>
              <li>{t("sv388Title2Desc4")}</li>
              <div>{t("sv388Title2Desc5")}</div>
              <li>{t("sv388Title2Desc6")}</li>
              <div>{t("sv388Title2Desc7")}</div>
              <li>{t("sv388Title2Desc8")}</li>
              <div>{t("sv388Title2Desc9")}</div>
            </ol>
          </div>
          <div className="info-text-title">{t("sv388Title3")}</div>
          <div className="info-text-desc">{t("sv388Title3Desc")}</div>
          <img src={cockFightingPng} width={300} alt="Cock Fighting" />
        </div>
      </div>
    </div>
  );
};

export default Sv388Page;
