import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Lucky365GamesImg from "../../components/lucky365games";
import { lucky365Slot, luckySlot365 } from "../../img";
import "../../stylings/gamepage.css";

const Lucky365SlotPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={lucky365Slot} width={200} alt="Lucky365" />
          <div className="info-container">
            <div className="info-title">{t("LUCKY365")}</div>
            <div className="info-desc">{t("lucky365MainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <Lucky365GamesImg />
        <div className="info-text-container">
          <div className="info-text-title">{t("lucky365Title1")}</div>
          <div className="info-text-desc">{t("lucky365Title1Desc")}</div>
          <div className="info-text-title2">{t("lucky365Title2")}</div>
          <div className="info-text-desc">{t("lucky365Title2Desc")}</div>
          <ol>
            <li className="text-title">{t("lucky365Title3")}</li>
            <div className="info-text-desc">{t("lucky365Title3Desc")}</div>
            <div className="info-text-title">{t("Sakura")}</div>
            <div className="info-text-desc">{t("lucky365Title4Desc")}</div>
            <div className="info-text-title">{t("Dolphin Reef")}</div>
            <div className="info-text-desc">{t("lucky365Title5Desc")}</div>
            <div className="info-text-title">{t("Simba")}</div>
            <div className="info-text-desc">{t("lucky365Title6Desc")}</div>
            <li className="text-title">{t("lucky365Title7")}</li>
            <div className="info-text-desc">{t("lucky365Title7Desc")}</div>
            <div className="info-text-title">{t("lucky365Title8")}</div>
            <div className="info-text-desc">{t("lucky365Title8Desc")}</div>
            <div className="info-text-title">{t("lucky365Title9")}</div>
            <div className="info-text-desc">{t("lucky365Title9Desc")}</div>
            <div className="info-text-title">{t("lucky365Title10")}</div>
            <div className="info-text-desc">{t("lucky365Title10Desc")}</div>
            <li className="text-title">{t("lucky365Title11")}</li>
            <div className="info-text-desc">{t("lucky365Title11Desc")}</div>
            <div className="info-text-title">{t("lucky365Title12")}</div>
            <div className="info-text-desc">{t("lucky365Title12Desc")}</div>
          </ol>
          <div className="info-text-title2">{t("lucky365Title13")}</div>
          <div className="info-text-desc">{t("lucky365Title13Desc")}</div>
          <div className="info-text-title2">{t("lucky365Title14")}</div>
          <img src={luckySlot365} alt="Lucky365 Game" />
          <div className="info-text-desc">{t("lucky365Title14Desc1")}</div>
          <div className="info-text-desc">{t("lucky365Title14Desc2")}</div>
          <div className="info-text-desc">{t("lucky365Title14Desc3")}</div>
          <div className="info-text-title2">{t("lucky365Title15")}</div>
          <div className="info-text-desc">{t("lucky365Title15Desc")}</div>
          <ul>
            <li className="info-text-title2">{t("lucky365Title16")}</li>
            <div className="info-text-desc">{t("lucky365Title16Desc")}</div>
            <li className="info-text-title2">{t("lucky365Title17")}</li>
            <div className="info-text-desc">{t("lucky365Title17Desc")}</div>
            <li className="info-text-title2">{t("lucky365Title18")}</li>
            <div className="info-text-desc">{t("lucky365Title18Desc")}</div>
            <li className="info-text-title2">{t("lucky365Title19")}</li>
            <div className="info-text-desc">{t("lucky365Title19Desc")}</div>
            <li className="info-text-title2">{t("lucky365Title20")}</li>
            <div className="info-text-desc">{t("lucky365Title20Desc")}</div>
            <li className="info-text-title2">{t("lucky365Title21")}</li>
            <div className="info-text-desc">{t("lucky365Title21Desc")}</div>
            <li className="info-text-title2">{t("lucky365Title22")}</li>
            <div className="info-text-desc">{t("lucky365Title22Desc")}</div>
          </ul>
          <div className="info-text-title2">{t("lucky365Title23")}</div>
          <div className="info-text-desc">{t("lucky365Title23Desc")}</div>
          <ul>
            <li className="info-text-title2">{t("lucky365Title24")}</li>
            <div className="info-text-desc">{t("lucky365Title24Desc")}</div>
            <li className="info-text-title2">{t("lucky365Title25")}</li>
            <div className="info-text-desc">{t("lucky365Title25Desc")}</div>
            <li className="info-text-title2">{t("lucky365Title26")}</li>
            <div className="info-text-desc">{t("lucky365Title26Desc")}</div>
            <li className="info-text-title2">{t("lucky365Title27")}</li>
            <div className="info-text-desc">{t("lucky365Title27Desc")}</div>
          </ul>
          <div className="info-text-title2">{t("lucky365Title28")}</div>
          <div className="info-text-desc">{t("lucky365Title28Desc")}</div>
        </div>
      </div>
    </div>
  );
};

export default Lucky365SlotPage;
