import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import FaqContentDisplay from "../components/faq/faqContent";
import { qna } from "../img";
import "../stylings/custom-antd.css";
import "../stylings/faqStyling.css";

const FAQ = () => {
  const { t } = useTranslation();

  return (
    <div className="faqPageContainer">
      <div className="faqContentContainer">
        <FaqContentDisplay />
      </div>
      <div className="feedbackContainer">
        <img src={qna} alt="Questions?" />
        <div className="feedbackTitle">{t("anyQues")}</div>
        <div className="greyFeedback">{t("anyQuesDesc")}</div>
        <div className="container">
          <Button className="feedbackSubmitBtn">{t("contactUs")}</Button>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
