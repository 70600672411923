import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  bgCasino,
  bgGamingBaccarat,
  bgGamingRoulette,
  bgGamingSicbo,
} from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const BigGamingCasinoPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={bgCasino} width={200} alt="Big Gaming" />
          <div className="info-container">
            <div className="info-title">{t("BIG GAMING")}</div>
            <div className="info-desc">{t("bigGamingMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("bigGamingTitle1")}</div>
          <div className="info-text-desc">{t("bigGamingTitle1Desc1")}</div>
          <div className="info-text-desc" style={{ padding: "0px 30px" }}>
            {t("bigGamingTitle1Desc2")}
          </div>
          <div className="info-text-desc">{t("bigGamingTitle1Desc3")}</div>
          <div className="info-text-title2">{t("bigGamingTitle2")}</div>
          <div className="info-text-desc">{t("bigGamingTitle2Desc1")}</div>
          <div className="info-text-desc">{t("bigGamingTitle2Desc2")}</div>
          <div className="info-text-title2">{t("bigGamingTitle3")}</div>
          <div className="info-text-desc">{t("bigGamingTitle3Desc1")}</div>
          <div className="info-text-desc">{t("bigGamingTitle3Desc2")}</div>
          <div className="info-text-desc">{t("bigGamingTitle3Desc3")}</div>
          <div className="info-text-desc">{t("bigGamingTitle3Desc4")}</div>
          <div className="info-text-title2">{t("bigGamingTitle4")}</div>
          <div className="info-text-desc">{t("bigGamingTitle4Desc")}</div>
          <div className="info-game">
            <img src={bgGamingBaccarat} width={200} alt="Big Gaming Baccarat" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("1. BACCARAT")}</div>
              <div className="info-text-desc">{t("bigGamingTitle5Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={bgGamingRoulette} width={200} alt="Big Gaming Roulette" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("2. ROULETTE")}</div>
              <div className="info-text-desc">{t("bigGamingTitle6Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={bgGamingSicbo} width={200} alt="Big Gaming SicBo" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("3. SICBO")}</div>
              <div className="info-text-desc">{t("bigGamingTitle7Desc")}</div>
            </div>
          </div>
          <div className="info-text-title">{t("bigGamingTitle8")}</div>
          <div className="info-text-desc">{t("bigGamingTitle8Desc1")}</div>
          <div className="info-text-desc">{t("bigGamingTitle8Desc2")}</div>
          <div className="info-text-title2">{t("bigGamingTitle9")}</div>
          <div className="info-text-desc">{t("bigGamingTitle9Desc1")}</div>
          <div className="info-text-desc">{t("bigGamingTitle9Desc2")}</div>
          <div className="info-text-title">{t("bigGamingTitle10")}</div>
          <div className="info-text-desc">{t("bigGamingTitle10Desc")}</div>
          <div className="info-text-title2">{t("bigGamingTitle11")}</div>
          <div className="info-text-desc">{t("bigGamingTitle11Desc")}</div>
          <div className="info-text-title">{t("bigGamingTitle12")}</div>
          <div className="info-text-desc">{t("bigGamingTitle12Desc")}</div>
        </div>
      </div>
    </div>
  );
};

export default BigGamingCasinoPage;
