import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  dogHouse,
  fruitParty,
  hercules,
  johnHunter,
  pragmaticSlot,
  pyramidKing,
  streetRacer,
  wilwestGold,
} from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const PragmaticSlotPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={pragmaticSlot} width={200} alt="Pragmatic Play" />
          <div className="info-container">
            <div className="info-title">{t("PRAGMATIC PLAY")}</div>
            <div className="info-desc">{t("pragmaticMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("pragmaticTitle1")}</div>
          <div className="info-text-desc">{t("pragmaticTitle1Desc1")}</div>
          <div className="info-text-desc">{t("pragmaticTitle1Desc2")}</div>
          <div className="info-text-title2">{t("pragmaticTitle2")}</div>
          <div className="info-text-title">{t("pragmaticTitle3")}</div>
          <div className="info-text-desc">{t("pragmaticTitle3Desc")}</div>
          <div className="info-text-title">{t("pragmaticTitle4")}</div>
          <div className="info-text-desc">{t("pragmaticTitle4Desc")}</div>
          <div className="info-text-title2">{t("pragmaticTitle5")}</div>
          <div className="info-text-desc">
            <ul>
              <li>{t("pragmaticTitle5Desc1")}</li>
              <div>{t("pragmaticTitle5Desc2")}</div>
              <li>{t("pragmaticTitle5Desc3")}</li>
              <div>{t("pragmaticTitle5Desc4")}</div>
              <li>{t("pragmaticTitle5Desc5")}</li>
              <div>{t("pragmaticTitle5Desc6")}</div>
            </ul>
          </div>
          <div className="info-text-title">{t("pragmaticTitle6")}</div>
          <div className="info-text-desc">{t("pragmaticTitle6Desc1")}</div>
          <div className="info-text-desc">
            <ul>
              <li>{t("pragmaticTitle6Desc2")}</li>
              <div>{t("pragmaticTitle6Desc3")}</div>
              <li>{t("pragmaticTitle6Desc4")}</li>
              <div>{t("pragmaticTitle6Desc5")}</div>
              <li>{t("pragmaticTitle6Desc6")}</li>
              <div>{t("pragmaticTitle6Desc7")}</div>
              <li>{t("pragmaticTitle6Desc8")}</li>
              <div>{t("pragmaticTitle6Desc9")}</div>
            </ul>
          </div>
          <div className="info-text-desc">{t("pragmaticTitle6Desc10")}</div>
          <div className="info-text-desc">{t("pragmaticTitle6Desc11")}</div>
          <div className="info-game">
            <img src={wilwestGold} alt="WILD WEST GOLD" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("WILD WEST GOLD")}</div>
              <div className="info-text-desc">{t("pragmaticTitle7Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={fruitParty} alt="FRUIT PARTY" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("FRUIT PARTY")}</div>
              <div className="info-text-desc">{t("pragmaticTitle8Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={streetRacer} alt="STREET RACER" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("STREET RACER")}</div>
              <div className="info-text-desc">{t("pragmaticTitle9Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={johnHunter} alt="JOHN HUNTER AND THE MAYAN GODS" />
            <div className="info-game-text">
              <div className="info-text-title2">
                {t("JOHN HUNTER AND THE MAYAN GODS")}
              </div>
              <div className="info-text-desc">{t("pragmaticTitle10Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={hercules} alt="HERCULES AND PEGASUS" />
            <div className="info-game-text">
              <div className="info-text-title2">
                {t("HERCULES AND PEGASUS")}
              </div>
              <div className="info-text-desc">{t("pragmaticTitle11Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={pyramidKing} alt="PYRAMID KING" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("PYRAMID KING")}</div>
              <div className="info-text-desc">{t("pragmaticTitle12Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={dogHouse} alt="DOG HOUSE MEGAWAYS" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("DOG HOUSE MEGAWAYS")}</div>
              <div className="info-text-desc">{t("pragmaticTitle13Desc")}</div>
            </div>
          </div>
          <div
            className="info-text-title2"
            style={{ textDecorationLine: "underline" }}
          >
            {t("pragmaticTitle14")}
          </div>
          <div className="info-text-title2">{t("pragmaticTitle15")}</div>
          <div className="info-text-desc">{t("pragmaticTitle15Desc1")}</div>
          <div className="info-text-desc">{t("pragmaticTitle15Desc2")}</div>
          <div className="info-text-title2">{t("pragmaticTitle16")}</div>
          <div className="info-text-desc">{t("pragmaticTitle16Desc")}</div>
          <div className="info-text-title2">{t("pragmaticTitle17")}</div>
          <div className="info-text-desc">{t("pragmaticTitle17Desc")}</div>
          <div className="info-text-title2">{t("pragmaticTitle18")}</div>
          <div className="info-text-desc">{t("pragmaticTitle18Desc1")}</div>
          <div className="info-text-desc">{t("pragmaticTitle18Desc2")}</div>
          <div className="info-text-title2">{t("pragmaticTitle19")}</div>
          <div className="info-text-desc">{t("pragmaticTitle19Desc1")}</div>
          <div className="info-text-desc">{t("pragmaticTitle19Desc2")}</div>
          <div className="info-text-desc">{t("pragmaticTitle19Desc3")}</div>
          <div className="info-text-desc">{t("pragmaticTitle19Desc4")}</div>
          <div className="info-text-title2">{t("pragmaticTitle20")}</div>
          <div className="info-text-desc">{t("pragmaticTitle20Desc1")}</div>
          <div className="info-text-desc">{t("pragmaticTitle20Desc2")}</div>
          <div className="info-text-title2">{t("pragmaticTitle21")}</div>
          <div className="info-text-desc">{t("pragmaticTitle21Desc1")}</div>
          <div className="info-text-desc">{t("pragmaticTitle21Desc2")}</div>
          <div className="info-text-title2">{t("pragmaticTitle22")}</div>
          <div className="info-text-desc">{t("pragmaticTitle22Desc1")}</div>
          <div className="info-text-desc">{t("pragmaticTitle22Desc2")}</div>
        </div>
      </div>
    </div>
  );
};

export default PragmaticSlotPage;
