import { Tabs } from "antd";
import React, { useState } from "react";
import PromoContent from "../components/promo/promoContent";
import "../stylings/promo.css";
import { useTranslation } from "react-i18next";

const tabs = [
  { key: 1, label: "all" },
  { key: 2, label: "newMember" },
  { key: 3, label: "daily" },
  { key: 4, label: "cashRebate" },
  { key: 5, label: "special" },
];

const Promo = () => {
  const { t } = useTranslation();
  const [promoTab, setPromoTab] = useState(1);
  const handlePromoTabChange = (value) => {
    setPromoTab(value);
  };

  return (
    <div className="promoMainContainer">
      <div className="promoMainContentBg">
        <Tabs
          defaultActiveKey="1"
          onChange={handlePromoTabChange}
          type="card"
          className="promoTab"
        >
          {tabs.map((tab) => (
            <Tabs.TabPane tab={t(tab.label)} key={tab.key}>
              <PromoContent />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};
export default Promo;
