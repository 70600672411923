import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { jiliGame1, jiliGame2, jiliGame3, jiliSlot } from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const JiliSlot = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={jiliSlot} width={200} alt="Jili" />
          <div className="info-container">
            <div className="info-title">{t("JILI GAME")}</div>
            <div className="info-desc">{t("jiliMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("jiliTitle1")}</div>
          <div className="info-text-desc">{t("jiliTitle1Desc1")}</div>
          <div className="info-text-desc">{t("jiliTitle1Desc2")}</div>
          <div className="info-text-desc">{t("jiliTitle1Desc3")}</div>
          <div className="info-text-title2">{t("jiliTitle2")}</div>
          <div className="info-text-desc">{t("jiliTitle2Desc")}</div>
          <div className="info-text-title2">{t("jiliTitle3")}</div>
          <div className="info-text-desc">{t("jiliTitle3Desc1")}</div>
          <div className="info-text-desc" style={{ padding: "0px 30px" }}>
            {t("jiliTitle3Desc2")}
          </div>
          <div className="info-text-title2">{t("jiliTitle4")}</div>
          <div className="info-text-desc">{t("jiliTitle4Desc")}</div>
          <div className="info-game">
            <img src={jiliGame1} width={300} alt="Jili Game 1" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("1. CHIN SHI HUANG")}</div>
              <div className="info-text-desc">{t("jiliTitle5Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={jiliGame2} width={300} alt="Jili Game 2" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("2. CHARGE BUFFALO")}</div>
              <div className="info-text-desc">{t("jiliTitle6Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={jiliGame3} width={300} alt="Jili Game 3" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("3. MONEY COMING")}</div>
              <div className="info-text-desc">{t("jiliTitle7Desc")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JiliSlot;
