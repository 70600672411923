import React from "react";
import HeaderMenu from "./headerMenuItems";

const Header = () => {
  return (
    <div className="headerbar">
      <div className="menucontainer">
        <HeaderMenu />
      </div>
    </div>
  );
};

export default Header;
