import React from "react";
import { useTranslation } from "react-i18next";

const BenefitItem = ({ imgSrc, title, description }) => {
  const { t } = useTranslation();
  return (
    <div className="benefitData">
      <img src={imgSrc} alt={title} />
      <div>{t(title)}</div>
      <div className="benefitDataDesc">{t(description)}</div>
    </div>
  );
};

export default BenefitItem;
