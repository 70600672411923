import { Trans } from "react-i18next";
import {
  cgPromotion1,
  cgPromotion2,
  cgPromotion3,
  cgPromotion4,
  cgPromotion5,
  cgPromotion6,
  cgPromotion7,
} from "../../img";

export const promoContent = [
  {
    img: cgPromotion1,
    title1: "promo1title1",
    title2: "promo1title2",
    desc: (
      <>
        <div>
          <Trans i18nKey="promo1Desc1" />
        </div>
        <div>
          <div>
            <Trans i18nKey="promo1Desc2" />
          </div>
          <div>
            <Trans i18nKey="promo1Desc3" />
          </div>
        </div>
        <div>
          <Trans i18nKey="promo1Desc4" />
        </div>
        <ol>
          <li>
            <Trans i18nKey="promo1Desc5" />
          </li>
          <li>
            <Trans i18nKey="promo1Desc6" />
          </li>
          <li>
            <Trans i18nKey="promo1Desc7" />
          </li>
          <li>
            <Trans i18nKey="promo1Desc8" />
          </li>
          <li>
            <Trans i18nKey="promo1Desc9" />
          </li>
          <li>
            <Trans i18nKey="promo1Desc10" />
          </li>
          <li>
            <Trans i18nKey="promo1Desc11" />
          </li>
        </ol>
        <div>
          <Trans i18nKey="promo1Desc12" />
        </div>
        <div>
          <Trans i18nKey="promo1Desc13" />
        </div>
      </>
    ),
  },
  {
    img: cgPromotion2,
    title1: "promo2title1",
    title2: "promo2title2",
    desc: "Detailed description for Free Ticket Value Update",
  },
  {
    img: cgPromotion3,
    title1: "promo3title1",
    title2: "promo3title2",
    desc: "Detailed description for Highest Exchange Rate",
  },
  {
    img: cgPromotion4,
    title1: "promo4title1",
    title2: "promo4title2",
    desc: "Detailed description for New Feature Mini Games",
  },
  {
    img: cgPromotion5,
    title1: "promo5title1",
    title2: "promo5title2",
    desc: "Detailed description for Cashback MYR 5",
  },
  {
    img: cgPromotion6,
    title1: "promo6title1",
    title2: "promo6title2",
    desc: "Detailed description for Get Promo with ATM Cash In",
  },
  {
    img: cgPromotion7,
    title1: "promo7title1",
    title2: "promo7title2",
    desc: "Detailed description for RM 5,000 daily maximum exchange amount",
  },
];
