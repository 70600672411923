import {
  agentReport,
  atmCashInBn,
  atmCashInEn,
  changeLangEn,
  internalTransferEn,
  sendMoneyEn,
  topUpEn,
  txnAmtEn,
} from "../../pdf/index.js";

export const userGuideContent = [
  {
    title: "atm1",
    contentFile: {
      en: atmCashInEn,
      cn: atmCashInEn,
      my: null,
      bn: atmCashInBn,
    },
  },
  {
    title: "language",
    contentFile: {
      en: changeLangEn,
      cn: internalTransferEn,
      my: agentReport,
      bn: internalTransferEn,
    },
  },
  {
    title: "internalTransfer",
    contentFile: {
      en: internalTransferEn,
      cn: changeLangEn,
      my: sendMoneyEn,
      bn: topUpEn,
    },
  },
  {
    title: "sendMoney",
    contentFile: {
      en: sendMoneyEn,
      cn: txnAmtEn,
      my: internalTransferEn,
      bn: agentReport,
    },
  },
  {
    title: "topUp",
    contentFile: {
      en: topUpEn,
      cn: sendMoneyEn,
      my: txnAmtEn,
      bn: agentReport,
    },
  },
  {
    title: "txnAmt",
    contentFile: {
      en: txnAmtEn,
      cn: agentReport,
      my: topUpEn,
      bn: sendMoneyEn,
    },
  },
  {
    title: "agentReport",
    contentFile: {
      en: agentReport,
      cn: topUpEn,
      my: changeLangEn,
      bn: txnAmtEn,
    },
  },
];
