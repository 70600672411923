import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  kingmakerGame1,
  kingmakerGame2,
  kingmakerGame3,
  kingmakerSlot,
} from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const KingmakerSlotPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={kingmakerSlot} width={200} alt="KingMaker" />
          <div className="info-container">
            <div className="info-title">{t("KINGMAKER x CashGo")}</div>
            <div className="info-desc">{t("kingmakerMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("kingmakerTitle1")}</div>
          <div className="info-text-desc">{t("kingmakerTitle1Desc1")}</div>
          <div className="info-text-desc">{t("kingmakerTitle1Desc2")}</div>
          <div className="info-text-title2">{t("kingmakerTitle2")}</div>
          <div className="info-text-desc">{t("kingmakerTitle2Desc1")}</div>
          <div className="info-text-desc">{t("kingmakerTitle2Desc2")}</div>
          <div className="info-text-title">{t("kingmakerTitle3")}</div>
          <div className="info-text-desc">{t("kingmakerTitle3Desc")}</div>
          <div className="info-text-title2">{t("kingmakerTitle4")}</div>
          <div className="info-game">
            <img src={kingmakerGame1} width={200} alt="KingMaker Game 1" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("1. TEEN PATTI")}</div>
              <div className="info-text-desc">{t("kingmakerTitle5Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={kingmakerGame2} width={200} alt="KingMaker Game 2" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("2. BÀI BỬU")}</div>
              <div className="info-text-desc">{t("kingmakerTitle6Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={kingmakerGame3} width={200} alt="KingMaker Game 3" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("3. POK DENG")}</div>
              <div className="info-text-desc">{t("kingmakerTitle7Desc")}</div>
            </div>
          </div>
          <div className="info-text-title2">{t("kingmakerTitle8")}</div>
          <div className="info-text-desc">{t("kingmakerTitle8Desc")}</div>
        </div>
      </div>
    </div>
  );
};

export default KingmakerSlotPage;
