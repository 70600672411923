import React from "react";
import { useTranslation } from "react-i18next";
import { horseGame, horseRacingBanner, horseracingMobileBanner } from "../img";
import "../stylings/custom-antd.css";
import "../stylings/faqStyling.css";
import { Link } from "react-router-dom";
import UseScreenSize from "../components/useScreenSize";

const HorseRacingPage = () => {
  const { t } = useTranslation();
  const screenSize = UseScreenSize();
  const isMobile = screenSize?.width < 768;

  return (
    <div className="game-page-display">
      <div>
        {" "}
        {isMobile ? (
          <img src={horseracingMobileBanner} alt="Horse Racing Banner" />
        ) : (
          <img
            src={horseRacingBanner}
            alt="Horse Racing Banner"
            style={{ width: "100vw" }}
          />
        )}
      </div>
      <div className="game-img-container">
        <div className="single-game-display">
          <Link to={"/rcb988"}>
            <div className="game-title">
              <img src={horseGame} alt="RCB988" width={250} />
              <div>{t("RCB988")}</div>
            </div>
          </Link>
        </div>
      </div>
      <div className="text-container">
        <div className="text-display">
          <div className="text-display-container">
            <div className="text-title">{t("horseRacingPageTitle")}</div>
            <div className="text-paragraph">
              {t("horseRacingPageTitleDesc-1")}
              <Link to={"/"} className="url-link2">
                {t("cashgoCasino")}
              </Link>
              <span>{t("horseRacingPageTitleDesc-2")}</span>
              <Link to={"/register"} className="url-link2">
                {t("registerCashGo")}
              </Link>
              <span>{t("horseRacingPageTitleDesc-3")}</span>
            </div>
            <div className="text-title">{t("horseRacingPageTitle2")}</div>
            <div className="text-paragraph">
              {t("horseRacingPageTitle2Desc")}
            </div>
            <div className="text-title">{t("horseRacingPageTitle3")}</div>
            <div className="text-paragraph">
              {t("horseRacingPageTitle3Desc")}
              <Link to={"/login"} className="url-link2">
                {t("loginCg")}
              </Link>
              <span>{t("horseRacingPageTitle3Desc2")}</span>
            </div>
          </div>
          <div className="text-display-container">
            <div className="text-title">{t("horseRacingPageTitle4")}</div>
            <div className="text-paragraph">
              {t("horseRacingPageTitle4Desc")}
              <Link to={"/register"} className="url-link2">
                {t("registerCashGo")}
              </Link>
              <span>{t("horseRacingPageTitle4Desc2")}</span>
              <span style={{ fontWeight: 500 }}>
                {t("horseRacingPageTitle4Desc3")}
              </span>
              <span>{t("horseRacingPageTitle4Desc4")}</span>
            </div>
            <div className="text-title">{t("horseRacingPageTitle5")}</div>
            <div className="text-paragraph">
              {t("horseRacingPageTitle5Desc")}
              <Link to={"/"} className="url-link2">
                {t("cashGoLink")}
              </Link>
              <span>{t("horseRacingPageTitle5Desc2")}</span>
            </div>
            <div className="text-title">{t("horseRacingPageTitle6")}</div>
            <div className="text-paragraph">
              {t("horseRacingPageTitle6Desc")}
            </div>
          </div>
          <div className="text-display-container">
            <div className="text-title">{t("horseRacingPageTitle7")}</div>
            <div className="text-paragraph">
              {t("horseRacingPageTitle7Desc")}
            </div>
            <div className="text-title">{t("horseRacingPageTitle8")}</div>
            <div className="text-paragraph">
              {t("horseRacingPageTitle8Desc1")}
            </div>
            <div className="text-paragraph">
              {t("horseRacingPageTitle8Desc2")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorseRacingPage;
