import React from "react";

const Register = () => {
  return (
    <div className="outerIframeContainer">
      <div className="iframe-container">
        <iframe
          src="https://cashgo88.com/register/user/?e=53138&kZPplAqo7VN9"
          title="Register"
          width="100%"
          height="600"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Register;
