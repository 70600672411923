import React from "react";
import { Button } from "antd";
import "../../stylings/promo.css";
import { useTranslation } from "react-i18next";

const PromoItem = ({ img, title1, title2, onClick }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="promoListingDisplay">
        <img src={img} alt={t(title1)} />
        <div className="listing">
          <div className="titleDisplay">
            <div className="promoTitle1">{t(title1)}</div>
            <div className="promoTitle2">{t(title2)}</div>
          </div>
          <div className="btnContainer">
            <Button className="moreInfoBtn" onClick={onClick}>
              {t("moreInfo")}
            </Button>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default PromoItem;
