import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { lionkingGame, lionkingSlot } from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const LionKingSlotPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={lionkingSlot} width={200} alt="Lion King" />
          <div className="info-container">
            <div className="info-title">{t("LION KING")}</div>
            <div className="info-desc">{t("lionKingMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("lionKingTitle1")}</div>
          <div className="info-text-desc">{t("lionKingTitle1Desc1")}</div>
          <div className="info-text-desc">{t("lionKingTitle1Desc2")}</div>
          <div className="info-text-title2">{t("lionKingTitle2")}</div>
          <div className="info-text-desc">{t("lionKingTitle2Desc")}</div>
          <div className="info-text-title2">{t("lionKingTitle3")}</div>
          <div className="info-text-desc">{t("lionKingTitle3Desc1")}</div>
          <div className="info-text-desc">{t("lionKingTitle3Desc2")}</div>
          <div className="info-text-title2">{t("lionKingTitle4")}</div>
          <div className="info-text-desc">{t("lionKingTitle4Desc")}</div>
          <div className="info-text-title2">{t("lionKingTitle5")}</div>
          <img src={lionkingGame} alt="Lion King Game" />
          <div className="info-text-title2">{t("lionKingTitle6")}</div>
          <div className="info-text-desc">{t("lionKingTitle6Desc")}</div>
          <div className="info-text-title2">{t("lionKingTitle7")}</div>
          <div className="info-text-desc">{t("lionKingTitle7Desc")}</div>
          <div className="info-text-title2">{t("lionKingTitle8")}</div>
          <div className="info-text-desc">{t("lionKingTitle8Desc")}</div>
          <div className="info-text-title2">{t("lionKingTitle9")}</div>
          <div className="info-text-desc">{t("lionKingTitle9Desc")}</div>
          <div className="info-text-title2">{t("lionKingTitle10")}</div>
          <div className="info-text-desc">{t("lionKingTitle10Desc")}</div>
          <div className="info-text-title2">{t("lionKingTitle11")}</div>
          <div className="info-text-desc">{t("lionKingTitle11Desc")}</div>
          <div className="info-text-title2">{t("lionKingTitle12")}</div>
          <div className="info-text-desc">{t("lionKingTitle12Desc")}</div>
          <div className="info-text-title2">{t("lionKingTitle13")}</div>
          <div className="info-text-desc">{t("lionKingTitle13Desc1")}</div>
          <div className="info-text-desc" style={{ padding: "0px 30px" }}>
            {t("lionKingTitle13Desc2")}
          </div>
          <div className="info-text-desc">{t("lionKingTitle13Desc3")}</div>
        </div>
      </div>
    </div>
  );
};

export default LionKingSlotPage;
