import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className=""
        style={{
          backgroundColor: "#05754f",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "justify",
          paddingTop: "80px",
          color: "white",
          height: "180px",
          borderBottomLeftRadius: "256px",
          borderBottomRightRadius: "256px",
        }}
      >
        <div className="" style={{ fontWeight: 700, fontSize: "24px" }}>
          {t("cgPolicy")}
        </div>
        <div
          className=""
          style={{ fontWeight: 500, fontSize: "20px", color: "#F2C330" }}
        >
          {t("Effective 22 Apr 2024")}
        </div>
      </div>
      <div
        style={{
          padding: "24px",
          color: "black",
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <div className="boldTitle">{t("policyTitle1")}</div>
        <div className="boldTitle">{t("policyTitle2")}</div>
        <div
          style={{
            fontSize: "14px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <div>{t("policyDesc")}</div>
          <div>{t("policyDesc2")}</div>
          <div>{t("policyDesc3")}</div>
          <div>{t("policyDesc4")}</div>
          <div>{t("policyDesc5")}</div>
          <div>{t("policyDesc6")}</div>
          <div>{t("policyDesc7")}</div>
          <div>{t("policyDesc8")}</div>
          <div>{t("policyDesc9")}</div>
          <div>
            {t("policyDesc10")}
            {t("cgEmail")}
          </div>
          <div style={{ fontSize: "16px" }}>{t("policyDesc11")}</div>
        </div>
        <ol style={{ fontSize: "14px" }}>
          <li style={{ listStyleType: "lower-alpha" }}>{t("policyDesc12")}</li>
          <li style={{ listStyleType: "lower-alpha" }}>{t("policyDesc13")}</li>
        </ol>
        <div>{t("policyDesc14")}</div>
        <ol style={{ fontSize: "14px" }}>
          <li className="boldTitle">{t("policy1")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("policy1Desc")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy1Desc2")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy1Desc3")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy1Desc4")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy1Desc5")}
            </li>
          </ol>
          <li className="boldTitle">{t("policy2")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("policy2Desc")}</li>
            <ol>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc2")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc3")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc4")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc5")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc6")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc7")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc8")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc9")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc10")}
              </li>
            </ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy2Desc11")}
            </li>
            <ol>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc12")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc13")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc14")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc15")}
              </li>
            </ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy2Desc16")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy2Desc17")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy2Desc18")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy2Desc19")}
            </li>
            <ol>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy2Desc20")}
              </li>
            </ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy2Desc21")}
            </li>
          </ol>
          <li className="boldTitle">{t("policy3")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("policy3Desc")}</li>
            <ol>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc2")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc3")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc4")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc5")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc6")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc7")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc8")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc9")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc10")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc11")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc12")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc13")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc14")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc15")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc16")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc17")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy3Desc18")}
              </li>
            </ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy3Desc19")}
            </li>
          </ol>
          <li className="boldTitle">{t("policy4")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("policy4Desc")}</li>
            <ol>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy4Desc2")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy4Desc3")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy4Desc4")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy4Desc5")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy4Desc6")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy4Desc7")}
              </li>
            </ol>
          </ol>
          <li className="boldTitle">{t("policy5")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("policy5Desc")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy5Desc2")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy5Desc3")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy5Desc4")}
            </li>
            <ol>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy5Desc5")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy5Desc6")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy5Desc7")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy5Desc8")}
              </li>
            </ol>
          </ol>
          <li className="boldTitle">{t("policy6")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("policy6Desc")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy6Desc2")}
            </li>
          </ol>
          <li className="boldTitle">{t("policy7")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("policy7Desc")}</li>
          </ol>
          <li className="boldTitle">{t("policy8")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("policy8Desc")}</li>
          </ol>
          <li className="boldTitle">{t("policy9")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>{t("policy9Desc")}</li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy9Desc2")}
            </li>
            <ol>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy9Desc3")}
              </li>
              <li style={{ listStyleType: "lower-roman" }}>
                {t("policy9Desc4")}
                {t("cgEmail")}
              </li>
            </ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy9Desc5")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy9Desc6")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy9Desc7")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy9Desc8")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy9Desc9")}
              {t("cgEmail")}
              {t("policy9Desc10")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy9Desc11")}
            </li>
          </ol>
          <li className="boldTitle">{t("policy10")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy10Desc")}
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy10Desc2")}
            </li>
          </ol>
          <li className="boldTitle">{t("policy11")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy11Desc")}
            </li>
          </ol>
          <li className="boldTitle">{t("policy12")}</li>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              {t("policy12Desc")}
            </li>
          </ol>
          <div style={{ marginLeft: "3px" }}>
            {t("email")}
            {t("cgEmail")}
          </div>
        </ol>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
