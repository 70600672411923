import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/en.json";
import cn from "./locales/cn/cn.json";
import my from "./locales/my/my.json";
import bn from "./locales/bn/bn.json";

const resources = {
  en: { translation: en },
  cn: { translation: cn },
  my: { translation: my },
  bn: { translation: bn },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
