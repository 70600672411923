import React from "react";

const News = () => {
  return (
    <div className="outerIframeContainer">
      <div className="iframe-container">
        <iframe
          src="https://www.prothomalo.com/"
          title="news"
          width="100%"
          height="600"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default News;
