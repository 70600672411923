import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  jdbGame1,
  jdbGame10,
  jdbGame11,
  jdbGame2,
  jdbGame3,
  jdbGame4,
  jdbGame5,
  jdbGame6,
  jdbGame7,
  jdbGame8,
  jdbGame9,
  jdbSlot,
} from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const JdbSlotPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={jdbSlot} width={200} alt="JDB Slot" />
          <div className="info-container">
            <div className="info-title">{t("jdbMainTitle")}</div>
            <div className="info-desc">{t("jdbMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("jdbTitle1")}</div>
          <div className="info-text-desc">{t("jdbTitle1Desc")}</div>
          <div className="info-text-title2">{t("jdbTitle2")}</div>
          <div className="info-text-desc">{t("jdbTitle2Desc1")}</div>
          <div className="info-text-desc">{t("jdbTitle2Desc2")}</div>
          <div className="info-text-title2">{t("jdbTitle3")}</div>
          <div className="info-game">
            <img src={jdbGame1} width={300} alt="JDB Slot Game 1" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("MJOLNIR")}</div>
              <div className="info-text-desc">{t("jdbTitle4Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={jdbGame2} width={300} alt="JDB Slot Game 2" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("BIRDS PARTY")}</div>
              <div className="info-text-desc">{t("jdbTitle5Desc")}</div>
            </div>
          </div>
          <div className="info-game">
            <img src={jdbGame3} width={300} alt="JDB Slot Game 3" />
            <div className="info-game-text">
              <div className="info-text-title2">{t("FORTUNE TREASURE")}</div>
              <div className="info-text-desc">{t("jdbTitle6Desc")}</div>
            </div>
          </div>
          <div className="info-text-title2">{t("jdbTitle7")}</div>
          <div className="info-text-desc">{t("jdbTitle7Desc1")}</div>
          <div className="info-text-desc">
            <ul>
              <li>{t("jdbTitle7Desc2")}</li>
              <div>{t("jdbTitle7Desc3")}</div>
              <li>{t("jdbTitle7Desc4")}</li>
              <div>{t("jdbTitle7Desc5")}</div>
              <li>{t("jdbTitle7Desc6")}</li>
              <div>{t("jdbTitle7Desc7")}</div>
              <li>{t("jdbTitle7Desc8")}</li>
              <div>{t("jdbTitle7Desc9")}</div>
              <li>{t("jdbTitle7Desc10")}</li>
              <div>{t("jdbTitle7Desc11")}</div>
            </ul>
          </div>
          <div className="info-text-title2">{t("jdbTitle8")}</div>
          <div className="info-text-desc">{t("jdbTitle8Desc1")}</div>
          <div className="info-text-desc">{t("jdbTitle8Desc2")}</div>
          <div className="game-img-container">
            <div className="game-img-display2">
              <div className="game-title">
                <img src={jdbGame1} alt="MJOLNIR" />
                <div>{t("MJOLNIR")}</div>
              </div>
              <div className="game-title">
                <img src={jdbGame4} alt="FUNKY KING KONG" />
                <div>{t("FUNKY KING KONG")}</div>
              </div>
              <div className="game-title">
                <img src={jdbGame5} alt="PIRATE TREASURE" />
                <div>{t("PIRATE TREASURE")}</div>
              </div>
              <div className="game-title">
                <img src={jdbGame6} alt="OPEN SESAME II" />
                <div>{t("OPEN SESAME II")}</div>
              </div>
              <div className="game-title">
                <img src={jdbGame7} alt="WINNING MASK" />
                <div>{t("WINNING MASK")}</div>
              </div>
            </div>
          </div>
          <div className="info-text-title">{t("jdbTitle9")}</div>
          <div className="info-text-desc">{t("jdbTitle9Desc1")}</div>
          <div className="info-text-desc">{t("jdbTitle9Desc2")}</div>
          <div className="game-img-container">
            <div className="game-img-display2">
              <div className="game-title">
                <img src={jdbGame2} alt="BIRDS PARTY" />
                <div>{t("BIRDS PARTY")}</div>
              </div>
              <div className="game-title">
                <img src={jdbGame8} alt="ORIENT ANIMALS" />
                <div>{t("ORIENT ANIMALS")}</div>
              </div>
              <div className="game-title">
                <img src={jdbGame9} alt="MAHJONG" />
                <div>{t("MAHJONG")}</div>
              </div>
              <div className="game-title">
                <img src={jdbGame10} alt="LUCKY 777" />
                <div>{t("LUCKY 777")}</div>
              </div>
              <div className="game-title">
                <img src={jdbGame11} alt="DRAGON WORLD" />
                <div>{t("DRAGON WORLD")}</div>
              </div>
            </div>
          </div>
          <div className="info-text-title2">{t("jdbTitle10")}</div>
          <div className="info-text-desc">{t("jdbTitle10Desc1")}</div>
          <div className="info-text-desc">{t("jdbTitle10Desc2")}</div>
          <div className="info-text-desc">{t("jdbTitle10Desc3")}</div>
        </div>
      </div>
    </div>
  );
};

export default JdbSlotPage;
