import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  aeSexyBaccarat,
  aeSexyCasino,
  aeSexyDragonTiger,
  aeSexyFishPrawnCrab,
  aeSexyLiveRoulette,
  aeSexySicBo,
  aeSexyThaiHilo,
} from "../../img";

const AeSexyCasinoPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={aeSexyCasino} width={200} alt="AE Sexy" />
          <div className="info-container">
            <div className="info-title">{t("aeSexyMainTitle")}</div>
            <div className="info-desc">{t("aeSexyMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("aeSexyTitle1")}</div>
          <div className="info-text-desc">{t("aeSexyTitle1Desc1")}</div>
          <div className="info-text-desc">{t("aeSexyTitle1Desc2")}</div>
          <div className="info-text-title2">{t("aeSexyTitle2")}</div>
          <div className="info-text-desc">{t("aeSexyTitle2Desc1")}</div>
          <div className="info-text-desc">{t("aeSexyTitle2Desc2")}</div>
          <div className="info-text-desc">{t("aeSexyTitle2Desc3")}</div>
          <div className="info-text-desc">{t("aeSexyTitle2Desc4")}</div>
          <div className="info-text-title2">{t("aeSexyTitle3")}</div>
          <div className="info-text-desc">{t("aeSexyTitle3Desc")}</div>
          <div className="game-img-container">
            <div className="game-img-display2">
              <div>
                <img src={aeSexyBaccarat} alt="AE Sexy Baccarat" />
                <div>{t("AE SEXY BACCARAT")}</div>
              </div>
              <div>
                <img src={aeSexyDragonTiger} alt="AE Sexy Dragon Tiger" />
                <div>{t("AE SEXY DRAGON TIGER")}</div>
              </div>
              <div>
                <img src={aeSexyFishPrawnCrab} alt="AE Sexy Fish Prawn Crab" />
                <div>{t("AE SEXY FISH PRAWN CRAB (NEW)")}</div>
              </div>
              <div>
                <img src={aeSexyThaiHilo} alt="AE Sexy Thai Hi Lo" />
                <div>{t("AE SEXY THAI HILO")}</div>
              </div>
              <div>
                <img src={aeSexySicBo} alt="AE Sexy SicBo" />
                <div>{t("AE SEXY SIC BO")}</div>
              </div>
              <div>
                <img src={aeSexyLiveRoulette} alt="AE Sexy Live Roulette" />
                <div>{t("AE SEXY LIVE ROULETTE")}</div>
              </div>
            </div>
          </div>
          <div className="info-text-title2">{t("aeSexyTitle4")}</div>
          <div className="info-text-desc">{t("aeSexyTitle4Desc1")}</div>
          <div className="info-text-desc">{t("aeSexyTitle4Desc2")}</div>
          <div className="info-text-desc">{t("aeSexyTitle4Desc3")}</div>
          <div className="info-text-desc">{t("aeSexyTitle4Desc4")}</div>
          <ul>
            <li className="info-text-desc">{t("aeSexyTitle4Desc5")}</li>
            <li className="info-text-desc">{t("aeSexyTitle4Desc6")}</li>
            <li className="info-text-desc">{t("aeSexyTitle4Desc7")}</li>
          </ul>
          <div className="info-text-desc">{t("aeSexyTitle4Desc8")}</div>
          <div className="info-text-desc">{t("aeSexyTitle4Desc9")}</div>
          <div className="info-text-title2">{t("aeSexyTitle5")}</div>
          <div className="info-text-desc">{t("aeSexyTitle5Desc")}</div>
          <ol>
            <li className="info-text-title2">{t("aeSexyTitle6")}</li>
            <div className="info-text-desc">{t("aeSexyTitle6Desc1")}</div>
            <div className="info-text-desc">{t("aeSexyTitle6Desc2")}</div>
            <div className="info-text-desc">{t("aeSexyTitle6Desc3")}</div>
            <li className="info-text-title2">{t("aeSexyTitle7")}</li>
            <div className="info-text-desc">{t("aeSexyTitle7Desc1")}</div>
            <ol>
              <li
                className="info-text-desc"
                style={{ listStyleType: "lower-alpha" }}
              >
                {t("aeSexyTitle7Desc2")}
              </li>
              <li
                className="info-text-desc"
                style={{ listStyleType: "lower-alpha" }}
              >
                {t("aeSexyTitle7Desc3")}
              </li>
              <li
                className="info-text-desc"
                style={{ listStyleType: "lower-alpha" }}
              >
                {t("aeSexyTitle7Desc4")}
              </li>
              <li
                className="info-text-desc"
                style={{ listStyleType: "lower-alpha" }}
              >
                {t("aeSexyTitle7Desc5")}
              </li>
            </ol>
            <li className="info-text-title2">{t("aeSexyTitle8")}</li>
            <div className="info-text-desc">{t("aeSexyTitle8Desc1")}</div>
            <ol>
              <li
                className="info-text-desc"
                style={{ listStyleType: "lower-alpha" }}
              >
                {t("aeSexyTitle8Desc2")}
              </li>
              <li
                className="info-text-desc"
                style={{ listStyleType: "lower-alpha" }}
              >
                {t("aeSexyTitle8Desc3")}
              </li>
              <li
                className="info-text-desc"
                style={{ listStyleType: "lower-alpha" }}
              >
                {t("aeSexyTitle8Desc4")}
              </li>
            </ol>
            <li className="info-text-title2">{t("aeSexyTitle9")}</li>
            <div className="info-text-desc">{t("aeSexyTitle9Desc")}</div>
          </ol>
          <div className="info-text-title2">{t("aeSexyTitle10")}</div>
          <div className="info-text-desc">{t("aeSexyTitle10Desc1")}</div>
          <div className="info-text-desc">{t("aeSexyTitle10Desc2")}</div>
          <div className="info-text-title2">{t("aeSexyTitle11")}</div>
          <div className="info-text-desc">{t("aeSexyTitle11Desc1")}</div>
          <div className="info-text-desc">{t("aeSexyTitle11Desc2")}</div>
          <div className="info-text-desc">{t("aeSexyTitle11Desc3")}</div>
          <div className="info-text-desc" style={{ fontStyle: "italic" }}>
            {t("aeSexyTitle11Desc4")}
          </div>
          <div className="info-text-desc">{t("aeSexyTitle11Desc5")}</div>
        </div>
      </div>
    </div>
  );
};

export default AeSexyCasinoPage;
