import React from "react";

const Lottery = () => {
  return (
    <div className="outerIframeContainer">
      <div className="iframe-container">
        <iframe
          src="https://hari4d.com/"
          title="lottery"
          width="100%"
          height="600"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Lottery;
