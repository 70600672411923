import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { horseGame } from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const RCB988Page = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={horseGame} width={200} alt="Horse Racing" />
          <div className="info-container">
            <div className="info-title">{t("RCB 988")}</div>
            <div className="info-desc">{t("rcb988MainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("rcb988title")}</div>
          <div className="info-text-desc">{t("rcb988titleDesc")}</div>
          <div className="info-text-title2">{t("rcb988title2")}</div>
          <div className="info-text-desc">{t("rcb988title2Desc")}</div>
          <div className="info-text-title2">{t("rcb988title3")}</div>
          <ol className="info-text-desc">
            <li>{t("rcb988title3Desc1")}</li>
            <li>{t("rcb988title3Desc2")}</li>
            <li>{t("rcb988title3Desc3")}</li>
          </ol>
          <div className="info-text-title">{t("rcb988title4")}</div>
          <div className="info-text-desc">{t("rcb988title4Desc")}</div>
          <div className="info-text-title">{t("rcb988title5")}</div>
          <div className="info-text-desc">{t("rcb988title5Desc1")}</div>
          <div className="info-text-desc">{t("rcb988title5Desc2")}</div>
          <div className="info-text-desc">{t("rcb988title5Desc3")}</div>
        </div>
      </div>
    </div>
  );
};

export default RCB988Page;
