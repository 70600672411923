import React from "react";
import { Route, Routes } from "react-router-dom";
import CasinoGamePage from "../../pages/CasinoGame";
import AeSexyCasinoPage from "../../pages/casinogames/AeSexy";
import Download from "../../pages/Download";
import EsportsGamePage from "../../pages/EsportsGame";
import FAQ from "../../pages/Faq";
import Home from "../../pages/Home";
import HorseRacingPage from "../../pages/HorseRacingPage";
import Login from "../../pages/LoginPage";
import LotteryGamePage from "../../pages/LotteryGame";
import Lottery from "../../pages/LotteryPage";
import News from "../../pages/NewsPage";
import PrivacyPolicy from "../../pages/PolicyPage";
import Promo from "../../pages/Promo";
import Recruitment from "../../pages/Recruitment";
import Register from "../../pages/RegisterPage";
import SlotGames from "../../pages/SlotGame";
import AcewinSlotPage from "../../pages/slotgames/Acewin";
import JdbSlotPage from "../../pages/slotgames/Jdb";
import JiliSlot from "../../pages/slotgames/Jili";
import KingmakerSlotPage from "../../pages/slotgames/Kingmaker";
import LionKingSlotPage from "../../pages/slotgames/Lionking";
import Lucky365SlotPage from "../../pages/slotgames/Lucky365";
import Mega888SlotPage from "../../pages/slotgames/Mega888";
import MonkeyKingSlotPage from "../../pages/slotgames/MonkeyKing";
import PragmaticSlotPage from "../../pages/slotgames/Pragmatic";
import SportsGamePage from "../../pages/SportsGame";
import Sport from "../../pages/SportsPage";
import TNC from "../../pages/TnCPage";
import UserGuide from "../../pages/UserGuide";
import HotRoadCasinoPage from "../../pages/casinogames/HotRoad";
import AsiaGamingCasinoPage from "../../pages/casinogames/AsiaGaming";
import BigGamingCasinoPage from "../../pages/casinogames/BigGaming";
import RCB988Page from "../../pages/horseracinggames/Rcb988";
import EkorPage from "../../pages/4dlotterygames/Ekor";
import E1SportPage from "../../pages/esportgames/E1sportgames";
import MaxbetPage from "../../pages/sportsgame/Maxbet";
import Sv388Page from "../../pages/sportsgame/SV388";
import SbobetPage from "../../pages/sportsgame/SBOBET";
import Wicket9Page from "../../pages/sportsgame/Wicket9";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/promotion" element={<Promo />} />
      <Route path="/download" element={<Download />} />
      <Route path="/recruitment" element={<Recruitment />} />
      <Route path="/guide" element={<UserGuide />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/tnc" element={<TNC />} />
      <Route path="/policy" element={<PrivacyPolicy />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/lottery" element={<Lottery />} />
      <Route path="/sport" element={<Sport />} />
      <Route path="/news" element={<News />} />

      <Route path="/slot" element={<SlotGames />} />
      <Route path="/jili" element={<JiliSlot />} />
      <Route path="/kingmaker" element={<KingmakerSlotPage />} />
      <Route path="/acewin" element={<AcewinSlotPage />} />
      <Route path="/jdb" element={<JdbSlotPage />} />
      <Route path="/lionking" element={<LionKingSlotPage />} />
      <Route path="/lucky365" element={<Lucky365SlotPage />} />
      <Route path="/pragmatic-play" element={<PragmaticSlotPage />} />
      <Route path="/monkeyking" element={<MonkeyKingSlotPage />} />
      <Route path="/mega888" element={<Mega888SlotPage />} />

      <Route path="/casino" element={<CasinoGamePage />} />
      <Route path="/ae-sexy" element={<AeSexyCasinoPage />} />
      <Route path="/hot-road" element={<HotRoadCasinoPage />} />
      <Route path="/asia-gaming" element={<AsiaGamingCasinoPage />} />
      <Route path="/big-gaming" element={<BigGamingCasinoPage />} />

      <Route path="/sportsbook" element={<SportsGamePage />} />
      <Route path="/maxbet" element={<MaxbetPage />} />
      <Route path="/sv388" element={<Sv388Page />} />
      <Route path="/sbobet" element={<SbobetPage />} />
      <Route path="/9-wicket" element={<Wicket9Page />} />

      <Route path="/4d-lottery" element={<LotteryGamePage />} />
      <Route path="/ekor" element={<EkorPage />} />

      <Route path="/esports" element={<EsportsGamePage />} />
      <Route path="/e1-sport" element={<E1SportPage />} />

      <Route path="/horseracing" element={<HorseRacingPage />} />
      <Route path="/rcb988" element={<RCB988Page />} />
    </Routes>
  );
};

export default AppRoutes;
