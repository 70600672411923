import React from "react";
import { useTranslation } from "react-i18next";
import "../stylings/custom-antd.css";
import "../stylings/gamepage.css";
import {
  acewinSlot,
  jdbSlot,
  jiliSlot,
  kingmakerSlot,
  lionkingSlot,
  lucky365Slot,
  mega888Game,
  monkeyKingGame,
  pragmaticSlot,
  slotPageBanner,
  slotsMobileBanner,
} from "../img";
import { Link } from "react-router-dom";
import UseScreenSize from "../components/useScreenSize";

const SlotGames = () => {
  const { t } = useTranslation();
  const screenSize = UseScreenSize();
  const isMobile = screenSize?.width < 768;

  return (
    <div className="game-page-display">
      <div>
        {isMobile ? (
          <img src={slotsMobileBanner} alt="Slot Banner" />
        ) : (
          <img src={slotPageBanner} alt="Slot Banner" />
        )}
      </div>
      <div className="game-img-container">
        <div className="game-img-display">
          <Link to={"/jili"}>
            <div className="game-title">
              <img src={jiliSlot} alt="JILI" />
              <div>{t("JILI")}</div>
            </div>
          </Link>
          <Link to={"/kingmaker"}>
            <div className="game-title">
              <img src={kingmakerSlot} alt="KingMaker" />
              <div>{t("KINGMAKER")}</div>
            </div>
          </Link>
          <Link to={"/acewin"}>
            <div className="game-title">
              <img src={acewinSlot} alt="AceWin" />
              <div>{t("ACEWIN")}</div>
            </div>
          </Link>
          <Link to={"/jdb"}>
            <div className="game-title">
              <img src={jdbSlot} alt="JDB" />
              <div>{t("JDB")}</div>
            </div>
          </Link>
          <Link to={"/lionking"}>
            <div className="game-title">
              <img src={lionkingSlot} alt="Lion King" />
              <div>{t("LION KING")}</div>
            </div>
          </Link>
          <Link to={"/lucky365"}>
            <div className="game-title">
              <img src={lucky365Slot} alt="Lucky365" />
              <div>{t("LUCKY 365")}</div>
            </div>
          </Link>
          <Link to={"/pragmatic-play"}>
            <div className="game-title">
              <img src={pragmaticSlot} alt="Pragmatic Play" />
              <div>{t("PRAGMATIC PLAY")}</div>
            </div>
          </Link>
          <Link to={"/monkeyking"}>
            <div className="game-title">
              <img src={monkeyKingGame} alt="Monkey King" />
              <div>{t("MONKEY KING")}</div>
            </div>
          </Link>
          <Link to={"/mega888"}>
            <div className="game-title">
              <img src={mega888Game} alt="MEGA888" />
              <div>{t("MEGA888")}</div>
            </div>
          </Link>
        </div>
      </div>
      <div className="text-container">
        <div className="text-display">
          <div className="text-display-container">
            <div className="text-title">{t("slotPageTitle1")}</div>
            <div className="text-paragraph">
              {t("slotPageTitle1Desc1")}
              <Link to={"/"} className="url-link2">
                {t("cashgoCasino")}
              </Link>
              <span>{t("slotPageTitle1Desc2")}</span>
              <Link to={"/"} className="url-link2">
                {t("registerCashGo")}
              </Link>
              <span>{t("slotPageTitle1Desc3")}</span>
            </div>
            <div className="text-title">{t("slotPageTitle2")}</div>
            <div className="text-paragraph">{t("slotPageTitle2Desc")}</div>
            <div className="text-paragraph">{t("slotPageTitle2Desc2")}</div>
            <div className="text-title">{t("slotPageTitle3")}</div>
            <div className="text-paragraph">
              {t("slotPageTitle3Desc1")}
              <Link to={"/"} className="url-link2">
                {t("loginCg")}
              </Link>
              <span>{t("slotPageTitle3Desc1-2")}</span>
            </div>
            <div className="text-paragraph">{t("slotPageTitle3Desc2")}</div>
          </div>
          <div className="text-display-container">
            <div className="text-title">{t("slotPageTitle4")}</div>
            <div className="text-paragraph">
              {t("slotPageTitle4Desc1")}
              <Link to={"/"} className="url-link2">
                {t("downloadCg")}
              </Link>
              <span>{t("slotPageTitle4Desc2")}</span>
            </div>
            <div className="text-title">{t("slotPageTitle5")}</div>
            <div className="text-paragraph">{t("slotPageTitle5Desc")}</div>
            <div className="text-paragraph">
              <ol>
                <li>{t("slotPageTitle5Desc2")}</li>
                <li>{t("slotPageTitle5Desc3")}</li>
                <li>{t("slotPageTitle5Desc4")}</li>
                <li>{t("slotPageTitle5Desc5")}</li>
              </ol>
            </div>
            <div className="text-paragraph">
              {t("slotPageTitle5Desc6")}
              <Link to={"/jili"} className="url-link2">
                {t("slotPageTitle5Desc7")}
              </Link>
              <span style={{ fontWeight: 600 }}>
                {t("slotPageTitle5Desc8")}
              </span>
              <span>{t("slotPageTitle5Desc9")}</span>
            </div>
            <div className="text-title">{t("slotPageTitle6")}</div>
            <div className="text-paragraph">{t("slotPageTitle6Desc")}</div>
          </div>
          <div className="text-display-container">
            <div className="text-title">{t("slotPageTitle7")}</div>
            <div className="text-paragraph">
              {t("slotPageTitle7Desc1")}
              <Link to={"/"} className="url-link2">
                {t("cashGoLink")}
              </Link>
              <span>{t("slotPageTitle7Desc2")}</span>
            </div>
            <div className="text-title">{t("slotPageTitle8")}</div>
            <div className="text-paragraph">
              <ol>
                <li>{t("slotPageTitle8Desc")}</li>
                <li>{t("slotPageTitle8Desc2")}</li>
                <li>{t("slotPageTitle8Desc3")}</li>
                <li>{t("slotPageTitle8Desc4")}</li>
                <li>{t("slotPageTitle8Desc5")}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlotGames;
