import { Drawer } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { cgIcon3 } from "../../img";
import LangPicker from "./languagePick";
import "../../stylings/custom-antd.css";
import { useTranslation } from "react-i18next";

const MenuDrawer = (e) => {
  const openDrawer = e?.open;
  const closeDrawer = e?.close;
  const headerMenu = e?.menuItems || [];

  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Drawer
      className="custom-drawer"
      title={
        <Link to="/">
          <img src={cgIcon3} alt="CashGo Icon" onClick={closeDrawer} />
        </Link>
      }
      placement="right"
      onClose={closeDrawer}
      open={openDrawer}
    >
      <LangPicker mobile={true} onLanguageChange={closeDrawer} />

      {headerMenu.map((item) => {
        if (item.label === "game" && item.subPages) {
          return item.subPages.map((subItem) => (
            <div
              key={subItem.path}
              className={`menuitems drawer ${
                location.pathname === subItem.path ? "active" : ""
              }`}
            >
              <Link to={subItem.path} onClick={closeDrawer}>
                {t(subItem.label)}
              </Link>
            </div>
          ));
        } else {
          return (
            <div
              key={item.path}
              className={`menuitems drawer ${
                location.pathname === item.path ? "active" : ""
              }`}
            >
              <Link to={item.path} onClick={closeDrawer}>
                {t(item.label)}
              </Link>
            </div>
          );
        }
      })}

      <div className="drawerBtn">
        <div className="register">
          <Link to="/register" onClick={closeDrawer}>
            {t("signUp")}
          </Link>
        </div>
        <div className="login">
          <Link to="/login" onClick={closeDrawer}>
            {t("login")}
          </Link>
        </div>
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
