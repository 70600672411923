import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const footerMenuItems = [
  { path: "/register", label: "register" },
  { path: "/login", label: "login" },
  { path: "/promotion", label: "promo" },
  { path: "/download", label: "download" },
  { path: "/recruitment", label: "recruit" },
  { path: "/faq", label: "faq" },
  { path: "/guide", label: "guide" },
  { path: "/lottery", label: "lottery" },
  { path: "/sport", label: "sport" },
  { path: "/news", label: "news" },
  { path: "/slot", label: "slot" },
  { path: "/casino", label: "casino" },
  { path: "/sportsbook", label: "sport2" },
  { path: "/4d-lottery", label: "lottery2" },
  { path: "/esports", label: "esports" },
  { path: "/horseracing", label: "horseracing" },
];

const FooterMenu = () => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <div className="footermenu">
      {footerMenuItems.map((item) => (
        <div
          key={item.path}
          className={`menuitems ${
            location.pathname === item.path ? "active" : ""
          }`}
        >
          <Link to={item.path}>{t(item.label)}</Link>
        </div>
      ))}
    </div>
  );
};

export default FooterMenu;
