import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { monkeyKingGame } from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const MonkeyKingSlotPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={monkeyKingGame} width={200} alt="Monkey King" />
          <div className="info-container">
            <div className="info-title">{t("MONKEY KING")}</div>
            <div className="info-desc">{t("monkeyKingMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("monkeyKingTitle1")}</div>
          <div className="info-text-desc">{t("monkeyKingTitle1Desc1")}</div>
          <div className="info-text-desc">{t("monkeyKingTitle1Desc2")}</div>
          <div className="info-text-desc">{t("monkeyKingTitle1Desc3")}</div>
          <div className="info-text-title">{t("monkeyKingTitle2")}</div>
          <ul>
            <li className="info-text-title2">{t("monkeyKingTitle3")}</li>
            <div className="info-text-desc">{t("monkeyKingTitle3Desc")}</div>
            <li className="info-text-title2">{t("monkeyKingTitle4")}</li>
            <div className="info-text-desc">{t("monkeyKingTitle4Desc")}</div>
            <li className="info-text-title2">{t("monkeyKingTitle5")}</li>
            <div className="info-text-desc">{t("monkeyKingTitle5Desc")}</div>
            <li className="info-text-title2">{t("monkeyKingTitle6")}</li>
            <div className="info-text-desc">{t("monkeyKingTitle6Desc")}</div>
            <li className="info-text-title2">{t("monkeyKingTitle7")}</li>
            <div className="info-text-desc">{t("monkeyKingTitle7Desc")}</div>
          </ul>
          <div className="info-text-title">{t("monkeyKingTitle8")}</div>
          <div className="info-text-desc">{t("monkeyKingTitle8Desc1")}</div>
          <div className="info-text-desc">{t("monkeyKingTitle8Desc2")}</div>
          <div className="info-text-title">{t("monkeyKingTitle9")}</div>
          <div className="info-text-desc">{t("monkeyKingTitle9Desc")}</div>
          <div className="info-text-title">{t("monkeyKingTitle10")}</div>
          <div className="info-text-desc">{t("monkeyKingTitle10Desc")}</div>
          <div className="info-text-title">{t("monkeyKingTitle11")}</div>
          <div className="info-text-desc">{t("monkeyKingTitle11Desc1")}</div>
          <div className="info-text-desc">{t("monkeyKingTitle11Desc2")}</div>
        </div>
      </div>
    </div>
  );
};

export default MonkeyKingSlotPage;
