import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "../../stylings/userGuide.css";
import { userGuideContent } from "./guideMenu";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const UserGuideDisplay = () => {
  const [selectedGuide, setSelectedGuide] = useState(
    userGuideContent[0]?.contentFile
  );
  const [activeButton, setActiveButton] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const { t, i18n } = useTranslation();

  const handleContentDisplay = (content, index) => {
    setSelectedGuide(content[i18n.language]);
    setActiveButton(index);
    setPageNumber(1);
    setNumPages(null);
  };

  useEffect(() => {
    if (userGuideContent[activeButton]) {
      setSelectedGuide(
        userGuideContent[activeButton].contentFile[i18n.language]
      );
      setPageNumber(1);
    }
  }, [i18n.language, activeButton]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToNextPage = () => {
    setPageNumber((prevPage) => Math.min(prevPage + 1, numPages));
  };

  const goToPreviousPage = () => {
    setPageNumber((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <div className="guideContainer">
      <div className="guideBtnContainer">
        {userGuideContent.map((item, index) => (
          <div key={index}>
            <Button
              className={`custom-antd-btn ant-btn-default ${
                activeButton === index ? "active" : ""
              }`}
              onClick={() => handleContentDisplay(item.contentFile, index)}
            >
              {t(item?.title)}
            </Button>
          </div>
        ))}
      </div>
      <div className="guidePDFdisplay">
        <Document file={selectedGuide} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} scale={0.75} />
        </Document>
        {numPages > 1 && (
          <div className="pageControls">
            <Button onClick={goToPreviousPage} disabled={pageNumber <= 1}>
              {t("previous")}
            </Button>
            <span>
              {t("page")} {pageNumber} {t("of")} {numPages}
            </span>
            <Button onClick={goToNextPage} disabled={pageNumber >= numPages}>
              {t("next")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserGuideDisplay;
