import axios from "axios";
import React, { useEffect, useState } from "react";
import UseScreenSize from "../useScreenSize";
import { useTranslation } from "react-i18next";

function CurrencyEx() {
  const apiUrl = "https://rate.cashgo1.com/api/v1/today-rate";
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("BDT");
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [apiData, setApiData] = useState(null);
  const currentDate = new Date();
  const screenSize = UseScreenSize();
  const isMobile = screenSize?.width < 768;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchApiData = async () => {
      try {
        const res = await axios.get(apiUrl);
        const data = res?.data?.data;
        setApiData(data);
      } catch (err) {
        console.log("error found here", err);
      }
    };
    fetchApiData();
  }, []);

  function formatDate(date) {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };

    const options2 = {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };

    const datePart = date.toLocaleDateString(undefined, options);
    const timePart = date.toLocaleTimeString([], options2);

    const formattedDate = `${datePart}, ${timePart}`;

    return formattedDate;
  }

  const formattedDate = formatDate(currentDate);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleConvertCurrency = (e) => {
    const finalAmt = amount * apiData?.exchange_rate;
    setConvertedAmount(finalAmt);
  };

  return (
    <div className="App-header">
      <div className="bg1">
        <div className="bg2">
          <div className="grid3">
            <div className="itemgrid">
              <span className="greenText text1">{t("amount")}*</span>
              <div>
                <input
                  className="bg3"
                  type="number"
                  min="1"
                  name="userInput"
                  placeholder="100"
                  value={amount}
                  onChange={handleAmountChange}
                />
              </div>
            </div>
            <div className="itemgrid">
              <span className="greenText text1">{t("from")}</span>
              <div>
                <select className="bg3" name="currency">
                  <option value="1">{t("myr")}</option>
                  <option value="2">{t("bdt")}</option>
                </select>
              </div>
            </div>
            <div className="itemgrid">
              <span className="greenText text1">To</span>
              <div>
                <select className="bg3" name="currency">
                  <option value="1">{t("bdt")}</option>
                  <option value="2">{t("myr")}</option>
                </select>
              </div>
            </div>
          </div>
          <div className="grid2">
            <div className="itemgrid">
              {isMobile && (
                <button
                  type="button"
                  className="orangeBtn"
                  onClick={handleConvertCurrency}
                >
                  {t("convert")}
                </button>
              )}
              <div className="smallSizeAmt">
                {isNaN(parseFloat(amount))
                  ? 0
                  : parseFloat(parseFloat(amount))
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                {t("myr(rm)")} =
              </div>
              <div className="convertedAmount" style={{ maxWidth: "80vw" }}>
                {parseFloat(convertedAmount)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                <span style={{ fontSize: "20px" }}>{currency}</span>
              </div>
              <div className="smallSizeAmt">
                {t("1myr")} ={" "}
                {isNaN(parseFloat(apiData?.exchange_rate))
                  ? "-"
                  : parseFloat(apiData?.exchange_rate).toFixed(2)}{" "}
                ({t("cashGoRate")})
              </div>
              <div className="smallSizeAmt">
                {t("1myr")} ={" "}
                {isNaN(parseFloat(apiData?.merchant))
                  ? "-"
                  : parseFloat(apiData?.merchant).toFixed(2)}{" "}
                ({t("otherRate")})
              </div>
            </div>
            <div className="itemgrid2">
              {!isMobile && (
                <button
                  type="button"
                  className="orangeBtn"
                  onClick={handleConvertCurrency}
                >
                  {t("convert")}
                </button>
              )}
              <div
                className="smallSizeAmt"
                style={{
                  textAlign: isMobile ? "left" : "right",
                  paddingTop: isMobile ? "0" : "30px",
                  marginBottom: isMobile ? "0" : "0",
                }}
              >
                {t("lastUpdate")} {formattedDate}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CurrencyEx;
