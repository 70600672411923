import { Tabs } from "antd";
import React from "react";
import FaqContentDisplayPanel from "./faqContentDisplayPanel";
import { faqContent } from "./faqData";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

const FaqContentDisplay = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="0" className="custom-antd-tabs">
      <TabPane tab={t("general")} key="0">
        <FaqContentDisplayPanel faq={faqContent.general} />
      </TabPane>
      <TabPane tab={t("accMgmt")} key="1">
        <FaqContentDisplayPanel faq={faqContent.account} />
      </TabPane>
      <TabPane tab={t("txnMgmt")} key="2">
        <FaqContentDisplayPanel faq={faqContent.transaction} />
      </TabPane>
    </Tabs>
  );
};

export default FaqContentDisplay;
