import { Button, Carousel } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CurrencyEx from "../components/converter/currencyEx.jsx";
import RatingDisplay from "../components/testimonialDisplay.jsx";
import UseScreenSize from "../components/useScreenSize.jsx";
import {
  agentElement2,
  androidQr,
  appStore,
  appleQr,
  appstoreLogo,
  cgPhoneApp,
  cgPromo,
  cgPromo2,
  cgPromo3,
  cgPromo4,
  cgPromo5,
  checkedIcon,
  family,
  high,
  homeElement,
  mobileElement2,
  pcLogo,
  playStore,
  playstoreLogo,
  secure,
} from "../img/index.js";
import "../stylings/home.css";
import "../stylings/recruitment.css";
import { cashGoVidEn } from "../video/index.js";

const Home = () => {
  const screenSize = UseScreenSize();
  const isMobile = screenSize?.width < 768;
  const { t } = useTranslation();

  return (
    <div>
      <div className="landingBlock">
        <div className="firstBlock">
          <div className="firstBlockContainer">
            <img
              className="firstBlockImgBg"
              src={isMobile ? mobileElement2 : homeElement}
              alt="CashGo App Benefits"
            />
            <div className="firstBlockTextContainer">
              <div
                className="orangeText"
                style={{
                  fontFamily: "Roboto Black",
                  fontWeight: 700,
                  fontSize: isMobile ? "28px" : "48px",
                }}
              >
                {t("myToBn")}
              </div>
              <div
                style={{
                  fontWeight: 500,
                  fontSize: isMobile ? "26px" : "36px",
                }}
              >
                {t("exchangePlatform")}
              </div>
              <div className="firstBlockSecContainer">
                <div className="mainQrContainer">
                  <div className="qRContainer">
                    <img src={appleQr} alt="Apple QR" className="qrImg" />
                    <img
                      src={appStore}
                      alt="App Store"
                      className="qrStoreImg"
                    />
                    <div className="comingSoonText">{t("cmingSoon")}</div>
                  </div>
                  <div className="qRContainer">
                    <img src={androidQr} alt="Android QR" className="qrImg" />
                    <img
                      src={playStore}
                      alt="Play Store"
                      className="qrStoreImg"
                    />
                  </div>
                </div>

                <div
                  style={{
                    fontSize: "14px",
                    width: "300px",
                  }}
                >
                  {t("downloadBoth")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="landingBlock">
        <div
          className="greenText pt-4"
          style={{
            fontWeight: "800",
            fontSize: isMobile ? "24px" : "30px",
            padding: isMobile ? "12px" : "0px",
          }}
        >
          {t("cgConverter")}
        </div>
        <div
          className="greenText"
          style={{
            fontWeight: "500",
            fontSize: isMobile ? "14px" : "18px",
            padding: isMobile ? "12px" : "0px",
          }}
        >
          {t("convertMYR")}
        </div>
        <div className="p-2">
          <CurrencyEx />
        </div>
      </div>
      <div>
        <video src={cashGoVidEn} autoPlay controls muted playsInline>
          {t("notSupportVideo")}
        </video>
      </div>

      <div className="landingBlock greyBg">
        <div
          className="pt-4"
          style={{
            fontWeight: "800",
            fontSize: isMobile ? "24px" : "30px",
            padding: isMobile ? "12px" : "0px",
          }}
        >
          <span style={{ color: "#000000" }}>{t("moneyChangerMsia")}</span>{" "}
          <span style={{ color: "#F7C212" }}>{t("bestRate")}</span>
        </div>
        <div
          style={{
            fontWeight: "500",
            fontSize: isMobile ? "14px" : "18px",
            color: "#000000",
            padding: isMobile ? "12px" : "0px",
          }}
        >
          {t("swiftExchange")}
        </div>
        <div className="flip-button-container">
          <Button className="flip-button" shape="circle">
            <div className="flip-button-inner">
              <div className="flip-button-front">
                <img src={secure} alt="Security" />
                {isMobile && (
                  <div>
                    <div className="title">{t("highProtect")}</div>
                    <div className="desc">{t("highProtectDesc")}</div>
                  </div>
                )}
              </div>
              {!isMobile && (
                <div className="flip-button-back">
                  <div className="title">{t("highProtect")}</div>
                  <div className="desc">{t("highProtectDesc")}</div>
                </div>
              )}
            </div>
          </Button>
          <Button className="flip-button" shape="circle">
            <div className="flip-button-inner">
              <div className="flip-button-front">
                <img src={high} alt="Higher Rate" />
                {isMobile && (
                  <div>
                    <div className="title">{t("highRate")}</div>
                    <div className="desc">{t("highRateDesc")}</div>
                  </div>
                )}
              </div>
              {!isMobile && (
                <div className="flip-button-back">
                  <div className="title">{t("highRate")}</div>
                  <div className="desc">{t("highRateDesc")}</div>
                </div>
              )}
            </div>
          </Button>
          <Button className="flip-button" shape="circle">
            <div className="flip-button-inner">
              <div className="flip-button-front">
                <img src={family} alt="Family" />
                {isMobile && (
                  <div>
                    <div className="title">{t("safeService")}</div>
                    <div className="desc">{t("safeServiceDesc")}</div>
                  </div>
                )}
              </div>
              {!isMobile && (
                <div className="flip-button-back">
                  <div className="title">{t("safeService")}</div>
                  <div className="desc">{t("safeServiceDesc")}</div>
                </div>
              )}
            </div>
          </Button>
        </div>
      </div>
      <div className="fourthBlock">
        <img
          src={cgPhoneApp}
          alt="CashGo App"
          style={{ height: "300px", width: "300px" }}
        />
        <div className="fourthBlockMainContainer">
          <div
            className="greenText"
            style={{
              fontWeight: isMobile ? 600 : 700,
              fontSize: isMobile ? "21px" : "36px",
              textAlign: isMobile ? "center" : "left",
            }}
          >
            {t("smoothExp1")}{" "}
            <span style={{ color: "white" }}>{t("smoothExp2")}</span>
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "14px",
              textAlign: isMobile ? "center" : "left",
            }}
          >
            {t("whetherPrefer1")}{" "}
            <span style={{ color: "black" }}>{t("whetherPrefer2")}</span> -
            {t("whetherPrefer2")}
          </div>
          {!isMobile && (
            <div
              style={{
                fontSize: "14px",
                fontWeight: 450,
                paddingTop: "12px",
                padding: isMobile ? "8px" : "0px",
                textAlign: isMobile ? "center" : "justify",
                width: isMobile ? "" : "500px",
              }}
            >
              {t("smoothExpDesc")}
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              padding: "4px",
            }}
          >
            <Button className="learnMoreBtn">
              <a
                href="https://download.cashgo88.com/cashgo.apk"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("downloadNow")}
              </a>
            </Button>
            <Button className="learnMoreBtn">
              <Link to={"register"}>{t("joinNow")}</Link>
            </Button>
          </div>
          <div className="fourthBlockQrMain">
            <div className="qRContainer">
              <Link to={"login"}>
                <img src={appleQr} alt="Apple QR" className="qrImg" />
                <img src={appStore} alt="App Store" className="qrStoreImg" />
              </Link>
              <div className="comingSoonText">{t("cmingSoon")}</div>
            </div>
            <div className="qRContainer">
              <a
                href="https://download.cashgo88.com/cashgo.apk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={androidQr} alt="Android QR" className="qrImg" />
                <img src={playStore} alt="Play Store" className="qrStoreImg" />
              </a>
            </div>
            <div className="qrTextContainer">
              <div>
                <div className="qrTextContainerWord">{t("appDownload")}</div>
                <div className="qrTextContainerBold">{t("11m")}</div>
              </div>
              <div>
                <div className="qrTextContainerWord">{t("userReview")}</div>
                <div className="qrTextContainerBold">{t("5star")}</div>
              </div>
              <div>
                <div className="qrTextContainerWord">{t("availableOn")}</div>
                <div className="fourthBlockAvailIconPack">
                  <img src={appstoreLogo} alt="App Store" />
                  <img src={playstoreLogo} alt="Play Store" />
                  <img src={pcLogo} alt="PC" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="landingBlock greyBg carouselMainContainer">
        <div className="carouselContainerImg">
          <Carousel autoplay arrows className="customCarouselSize" dots={false}>
            <img src={cgPromo} alt="Cash Go Promo" />
            <img src={cgPromo2} alt="Cash Go Promo2" />
            <img src={cgPromo3} alt="Cash Go Promo3" />
            <img src={cgPromo4} alt="Cash Go Promo4" />
            <img src={cgPromo5} alt="Cash Go Promo5" />
          </Carousel>
        </div>
        <div className="carouselContainerDesc">
          <div className="title1">
            <span style={{ color: "#000000" }}>{t("doubleReward1")}</span>{" "}
            <span style={{ color: "#067952" }}>{t("cash")}</span>
            <span style={{ color: "#F09A33" }}>{t("go")}</span>
          </div>
          <div className="title2">{t("userReceive")}</div>
          <div className="benefitContainerHome">
            <img src={checkedIcon} alt="Checked Icon" />
            <span>{t("cashBack")}</span>
          </div>
          <div className="benefitContainerHome" style={{ paddingTop: 0 }}>
            <img src={checkedIcon} alt="Checked Icon" />
            <span>{t("iboxTix")}</span>
          </div>
        </div>
      </div>
      <div className="recruitFirstBlock">
        <div className="blockContainer">
          <div className="firstBlockContent">
            <div className="firstBlockTitle">
              {t("joinCg1")} <span className="orangeText">{t("joinCg2")}</span>
            </div>
            <div style={{ fontWeight: 300, fontSize: "14px" }}>
              {t("joinCgDesc")}
            </div>

            <Button className="learnMoreBtn">
              <Link to={"recruitment"}>{t("learnMore")}</Link>
            </Button>
            <Button className="learnMoreBtn">{t("contactUs")}</Button>
          </div>
          <img
            className="highCommsImgBg"
            src={isMobile ? agentElement2 : agentElement2}
            alt="High Commissions"
          />
        </div>
      </div>
      <div className="ratingBlockContainer">
        <div className="title">
          <span style={{ color: "#000000" }}>{t("userSays1")}</span>{" "}
          <span style={{ color: "#F7C212" }}>{t("userSays2")}</span>
        </div>
        <div className="smallTitle">{t("userExp")}</div>
        <div className="valuedTesti">{t("testimonial")}</div>
        <div>
          <RatingDisplay />
        </div>
      </div>
      <div className="compareBlockDisplay">
        <div className="innerDiv">
          <div className="content1">
            {t("compare1")}{" "}
            <span style={{ color: "#F4AC0A" }}>{t("compare2")}</span>
          </div>
          <div className="content2">{t("compareDesc")}</div>
        </div>
      </div>
    </div>
  );
};
export default Home;
