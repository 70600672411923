import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ekorGame1, ekorGame2, ekorLottery } from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const EkorPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={ekorLottery} width={200} alt="EKOR" />
          <div className="info-container">
            <div className="info-title">{t("EKOR")}</div>
            <div className="info-desc">{t("ekorMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title">{t("ekorPageTitle1")}</div>
          <div className="info-text-desc">{t("ekorPageTitle1Desc1")}</div>
          <div className="info-text-desc">{t("ekorPageTitle1Desc2")}</div>
          <div className="info-text-title2">{t("ekorPageTitle2")}</div>
          <div className="info-text-desc">{t("ekorPageTitle2Desc")}</div>
          <div className="info-text-title2">{t("ekorPageTitle3")}</div>
          <div className="info-text-desc">{t("ekorPageTitle3Desc")}</div>
          <div className="info-text-title">{t("ekorPageTitle4")}</div>
          <div className="info-text-desc">{t("ekorPageTitle4Desc")}</div>
          <img src={ekorGame1} alt="EKOR Game 1" />
          <div className="info-text-title">{t("ekorPageTitle5")}</div>
          <div className="info-text-desc">{t("ekorPageTitle5Desc")}</div>
          <div className="info-text-title">{t("ekorPageTitle6")}</div>
          <div className="info-game-container">
            <img src={ekorGame2} alt="EKOR Game 2" width={230} />
            <div className="info-game-display">
              <div className="info-text-desc">{t("ekorPageTitle6Desc1")}</div>
              <div className="info-text-desc">{t("ekorPageTitle6Desc2")}</div>
              <div className="info-text-title2">{t("ekorPageTitle7")}</div>
              <div className="info-text-desc">{t("ekorPageTitle7Desc")}</div>
              <div className="info-text-title2">{t("ekorPageTitle8")}</div>
              <div className="info-text-desc">{t("ekorPageTitle8Desc")}</div>
              <div className="info-text-title2">{t("ekorPageTitle9")}</div>
              <div className="info-text-desc">{t("ekorPageTitle9Desc")}</div>
            </div>
          </div>
          <div className="info-text-title2">{t("ekorPageTitle10")}</div>
          <div className="info-text-desc">{t("ekorPageTitle10Desc1")}</div>
          <div className="info-text-desc">{t("ekorPageTitle10Desc2")}</div>
        </div>
      </div>
    </div>
  );
};

export default EkorPage;
