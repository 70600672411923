import React from "react";
import { useTranslation } from "react-i18next";
import {
  sports9wicket,
  sportsMaxbet,
  sportsMobileBanner,
  sportsPageBanner,
  sportsSbobet,
  sportsSv388,
} from "../img";
import "../stylings/custom-antd.css";
import "../stylings/faqStyling.css";
import { Link } from "react-router-dom";
import UseScreenSize from "../components/useScreenSize";

const SportsGamePage = () => {
  const { t } = useTranslation();
  const screenSize = UseScreenSize();
  const isMobile = screenSize?.width < 768;

  return (
    <div className="game-page-display">
      <div>
        {isMobile ? (
          <img src={sportsMobileBanner} alt="Sportsbook Banner" />
        ) : (
          <img src={sportsPageBanner} alt="Sportsbook Banner" />
        )}
      </div>
      <div className="game-img-container">
        <div className="game-img-display">
          <Link to={"/maxbet"}>
            <div className="game-title">
              <img src={sportsMaxbet} alt="Maxbet" />
              <div>{t("MAXBET")}</div>
            </div>
          </Link>
          <Link to={"/sv388"}>
            <div className="game-title">
              <img src={sportsSv388} alt="SV388" />
              <div>{t("SV388")}</div>
            </div>
          </Link>
          <Link to={"/sbobet"}>
            <div className="game-title">
              <img src={sportsSbobet} alt="SBOBET" />
              <div>{t("SBOBET")}</div>
            </div>
          </Link>
          <Link to={"/9-wicket"}>
            <div className="game-title">
              <img src={sports9wicket} alt="9 Wicket" />
              <div>{t("9 WICKET")}</div>
            </div>
          </Link>
        </div>
      </div>
      <div className="text-container">
        <div className="text-display">
          <div className="text-display-container">
            <div className="text-title">{t("sportsPageTitle")}</div>
            <div className="text-paragraph">
              {t("sportsPageTitleDesc")}
              <Link to={"/"} className="url-link2">
                {t("cashgoCasino")}
              </Link>
              <span>{t("sportsPageTitleDesc2")}</span>
            </div>
            <div className="text-title">{t("sportsPageTitle2")}</div>
            <div className="text-paragraph">{t("sportsPageTitle2Desc1")}</div>
            <div className="text-paragraph">
              <ul>
                <li>{t("sportsPageTitle2Desc2")}</li>
                <li>{t("sportsPageTitle2Desc3")}</li>
                <li>{t("sportsPageTitle2Desc4")}</li>
                <li>{t("sportsPageTitle2Desc5")}</li>
              </ul>
            </div>
            <div className="text-title">{t("sportsPageTitle3")}</div>
            <div className="text-paragraph">
              {t("sportsPageTitle3Desc")}
              <Link to={"/download"} className="url-link2">
                {t("downloadCg")}
              </Link>
              <span>{t("sportsPageTitle3Desc2")}</span>
            </div>
          </div>
          <div className="text-display-container">
            <div className="text-title">{t("sportsPageTitle4")}</div>
            <div className="text-paragraph">
              {t("sportsPageTitle4Desc")}
              <Link to={"/register"} className="url-link2">
                {t("registerCashGo")}
              </Link>
              <span>{t("sportsPageTitle4Desc2")}</span>
            </div>
            <div className="text-title">{t("sportsPageTitle5")}</div>
            <div className="text-paragraph">{t("sportsPageTitle5Desc")}</div>
            <div className="text-paragraph">
              <ol>
                <li className="text-title">{t("sportsPageTitle6")}</li>
                <div className="text-paragraph">
                  {t("sportsPageTitle6Desc")}
                  <Link to={"/maxbet"} className="url-link2">
                    {t("maxbet")}
                  </Link>
                  <span>{t("sportsPageTitle6Desc2")}</span>
                </div>
                <li className="text-title">{t("sportsPageTitle7")}</li>
                <div className="text-paragraph">
                  {t("sportsPageTitle7Desc")}
                </div>
                <li className="text-title">{t("sportsPageTitle8")}</li>
                <div className="text-paragraph">
                  {t("sportsPageTitle8Desc")}
                  <Link to={"/sbobet"} className="url-link2">
                    {t("sbobet")}
                  </Link>
                  <span>{t("sportsPageTitle8Desc2")}</span>
                </div>
              </ol>
            </div>
          </div>
          <div className="text-display-container">
            <div className="text-title">{t("sportsPageTitle9")}</div>
            <div className="text-paragraph">{t("sportsPageTitle9Desc")}</div>
            <div className="text-title">{t("sportsPageTitle10")}</div>
            <div className="text-paragraph">
              {t("sportsPageTitle10Desc1")}
              <Link to={"/login"} className="url-link2">
                {t("loginCg")}
              </Link>
              <span>{t("sportsPageTitle10Desc1-2")}</span>
            </div>
            <div className="text-paragraph">
              <ol>
                <li>{t("sportsPageTitle10Desc2")}</li>
                <div className="text-paragraph">
                  {t("sportsPageTitle10Desc3")}
                </div>
                <li>{t("sportsPageTitle10Desc4")}</li>
                <li>{t("sportsPageTitle10Desc5")}</li>
              </ol>
            </div>
            <div className="text-title">{t("sportsPageTitle11")}</div>
            <div className="text-paragraph">
              <ol>
                <li>{t("sportsPageTitle11Desc1")}</li>
                <li>{t("sportsPageTitle11Desc2")}</li>
                <li>{t("sportsPageTitle11Desc3")}</li>
                <div className="text-paragraph">
                  {t("sportsPageTitle11Desc4")}
                  <Link to={"/esports"} className="url-link2">
                    {t("esportsbetting")}
                  </Link>
                  <span>{t("sportsPageTitle11Desc5")}</span>
                </div>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SportsGamePage;
