import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { hotroadCasino, hotroadDragon, hotroadPingPong } from "../../img";
import "../../stylings/custom-antd.css";
import "../../stylings/faqStyling.css";

const HotRoadCasinoPage = () => {
  const { t } = useTranslation();

  return (
    <div className="game-page-display">
      <div className="game-info-container">
        <div className="game-info">
          <img src={hotroadCasino} width={200} alt="Hot Road" />
          <div className="info-container">
            <div className="info-title">{t("hotRoadMainTitle")}</div>
            <div className="info-desc">{t("hotRoadMainDesc")}</div>
          </div>
          <Link to={"/login"}>
            <Button className="play-now-btn">{t("playNow")}</Button>
          </Link>
        </div>
      </div>
      <div className="info-text-display">
        <div className="info-text-container">
          <div className="info-text-title2">{t("hotRoadTitle1")}</div>
          <div className="info-text-desc">{t("hotRoadTitle1Desc1")}</div>
          <div className="info-text-desc">{t("hotRoadTitle1Desc2")}</div>
          <div className="info-text-title2">{t("hotRoadTitle2")}</div>
          <div className="info-text-desc">{t("hotRoadTitle2Desc1")}</div>
          <div className="info-text-desc">{t("hotRoadTitle2Desc2")}</div>
          <img src={hotroadDragon} alt="Hot Road Dragon" />
          <div className="info-text-title2">{t("hotRoadTitle3")}</div>
          <div className="info-text-desc">{t("hotRoadTitle3Desc")}</div>
          <img src={hotroadPingPong} alt="Hot Road Ping Pong" />
          <div className="info-text-title2">{t("hotRoadTitle4")}</div>
          <div className="info-text-desc">{t("hotRoadTitle4Desc")}</div>
          <div className="info-text-title2">{t("hotRoadTitle5")}</div>
          <div className="info-text-desc">{t("hotRoadTitle5Desc")}</div>
        </div>
      </div>
    </div>
  );
};

export default HotRoadCasinoPage;
